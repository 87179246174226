import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MetaService } from '../shared/canonical.service';

@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.css']
})
export class ImagesComponent implements OnInit,OnDestroy {
  imgSrc = "/assets/images/";
  constructor(@Inject(ActivatedRoute) private route: ActivatedRoute,private router: Router, 
  private metaService: MetaService) { }

  ngOnInit() {
    // window.scroll(0,0);
    let href = this.router.url;
    this.metaService.createCanonicalURL(href);
    this.imgSrc += this.route.snapshot.params.file;
  }
  ngOnDestroy() {
    // $('.customer-logos').slick('unslick');
    // this.metaService.removeCanonicalLink();
  }
}
