<!-- career Banner -->
<section class="w-100 float-left about_us_page">
    <div class="container">
        <div class="w-100 float-left">
            <h1 class="banner_title text-center">Join Our Thriving Family</h1>
            <h2 class="banner_sub_title text-center">Boost Your Career with Leading IT Company</h2>
        </div>
    </div>
</section>
<section class="w-100 float-left">
    <div class=" container">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb ">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Career</li>
            </ol>
        </nav>
    </div>
</section>

<!-- career page about -->
<section class="w-100 float-left career_about">
    <div class="container">
        <div class="career_content float-left w-100">
            <div class="main_title d-flex justify-content-center" data-aos="zoom-in">
                <div class="line"><span>Career & </span> Culture </div>
            </div>
            <p class="float-left text-center" data-aos="zoom-in">
                We have combined career with the work culture to give you a robust platform to learn new things, use
                cutting-edge tools, and implement creative insights while making advanced IT solutions. Since inception,
                InheritX Solutions remains client-centric and employee-oriented company. We make sure that you can go
                ahead in a healthily competitive environment without having much stress.
            </p>
            <p class="float-left text-center" data-aos="zoom-in">
                At InheritX, we believe in ‘Work Hard, Party Harder’ policy. As a fresher, you will get support from
                experienced seniors, and as an experienced professional, you will have a collaborative and cooperative
                work culture. We never say that InheritX is the best company to work with, we have made it!
            </p>
            <p class="float-left text-center" data-aos="zoom-in">
                If you have dedication, creativity, and a lot of enthusiasm to learn new things, we’re pleased to
                welcome you to our growing family…
            </p>
            <div class="w-100 float-left d-flex justify-content-center" data-aos="zoom-in-up">
                <button class="main_btn text-uppercase mt-3" (click)="scroll(target)">View opening</button>
            </div>
        </div>
    </div>
</section>

<!-- Work Culture -->
<section class="w-100 float-left work_culture">
    <div class="container">
        <div class="w-100 float-left">
            <div class="main_title d-flex justify-content-center" data-aos="zoom-in">
                <div class="line"><span>Work </span> Culture </div>
            </div>
            <div class="sub_title text-center" data-aos="zoom-in">We Inspire You to Come Up with Your Best
            </div>
        </div>
        <div class="work_culture_about w-100 float-left">
            <div class="row">

                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12" data-aos="flip-up">
                    <div class="work_culture_content float-left w-100">
                        <div class="work_culture_img float-left">
                            <img src="assets/images/career/hours.png" alt="image">
                        </div>
                        <div class="float-right work_culture_inner_content">
                            <div class="work_culture_title">Flexible working hours</div>
                            <p>We’re a work-oriented and result-driven IT company. Though work is our priority, we
                                provide flexible working hours to help you reduce stress</p>
                        </div>
                    </div>
                </div>

                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12" data-aos="flip-up">
                    <div class="work_culture_content float-left w-100">
                        <div class="work_culture_img float-left">
                            <img src="assets/images/career/activity.png" alt="image">
                        </div>
                        <div class="float-right work_culture_inner_content">
                            <div class="work_culture_title">Company Activities</div>
                            <p>Cultural and fun activities are an integral part of InheritX Solutions. We work, play,
                                and celebrate together like a happy family</p>
                        </div>
                    </div>
                </div>

                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12" data-aos="flip-up">
                    <div class="work_culture_content float-left w-100">
                        <div class="work_culture_img float-left">
                            <img src="assets/images/career/worker.png" alt="image">
                        </div>
                        <div class="float-right work_culture_inner_content">
                            <div class="work_culture_title">Awesome Co-Workers</div>
                            <p>Seniors are supporting and colleagues are cordial- this is the real identity of InheritX
                                Solutions. We’re different by the department but we’re one as a team</p>
                        </div>
                    </div>
                </div>

                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12" data-aos="flip-up">
                    <div class="work_culture_content float-left w-100">
                        <div class="work_culture_img float-left">
                            <img src="assets/images/career/opportunity.png" alt="image">
                        </div>
                        <div class="float-right work_culture_inner_content">
                            <div class="work_culture_title">Growth Opportunity</div>
                            <p>Whether it is monetary or personal growth, InheritX Solutions remains the best place to
                                offer equal opportunities to all its employees. We believe in ‘Grow Together’ mantra.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12" data-aos="flip-up">
                    <div class="work_culture_content float-left w-100">
                        <div class="work_culture_img float-left">
                            <img src="assets/images/career/five-day-working.png" alt="image">
                        </div>
                        <div class="float-right work_culture_inner_content">
                            <div class="work_culture_title">Five-day Working</div>
                            <p>We have a five-day working policy to maintain healthy work-life balance. We ensure you
                                get new energy on Monday after having a stress-free weekend</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Life inheritx -->

<section class="w-100 float-left life_section" id="section3">
    <div class="container">
        <div class="w-100 float-left">
            <div class="main_title d-flex justify-content-center" data-aos="zoom-in">
                <div class="line"><span>Life @ </span> InheritX </div>
            </div>
            <div class="sub_title text-center" data-aos="zoom-in">Every Employee is a Valuable Asset for Us. We nurture
                their skills and care for their growth.
            </div>
        </div>
        <div class="life_image_section w-100 float-left img-scroll" data-aos="zoom-in">
            <div class="row" *ngIf="gallery.length>0">
                <div *ngFor="let g of gallery; let last = last"
                    class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 thumb" >
                    <a href="{{g.gallery_image}}" class="fancybox" rel="ligthbox">
                        <img [src]="g.gallery_image" class="zoom img-fluid ">
                    </a>
                </div>
            </div>
        </div>
        <div class="w-100 float-left mt-3">
            <div class="w-100 float-left d-flex justify-content-center flex-sm-row flex-column align-items-center">
                <span class="view_more_photos"> View More Photos Go to :</span>
                <div class="footer_social_icon_section float-left d-flex justify-content-center">
                    <a *ngFor="let link of socialLinks" [href]="link.url" target="_blank">
                        <div class="social_icon_box">
                          <img [src]="link.src" />
                        </div>
                      </a>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Employee Speaks -->
<section class="float-left w-100 employee_speaks">
    <div class="container">
        <div class="employee_speaks_slider w-100 float-left">
            <div class="row" data-aos="zoom-in">
                <div class="col-center m-auto">
                    <div class="main_title d-flex justify-content-center" data-aos="zoom-in">
                        <h2 class="line"><span>Employee </span> Speaks </h2>
                    </div>
                    <h3 class="sub_title text-center" data-aos="zoom-in">Here’s What Our Employees Say</h3>
                    <div id="myCarousel" class="carousel slide" data-ride="carousel">
                        <!-- Carousel indicators -->
                        <ol class="carousel-indicators">
                            <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
                            <li data-target="#myCarousel" data-slide-to="1"></li>
                            <li data-target="#myCarousel" data-slide-to="2"></li>
                        </ol>
                        <!-- Wrapper for carousel items -->
                        <div class="carousel-inner">
                            <div class="item carousel-item text-center active">
                                <p class="w-100 float-left text-center">I am working in InheritX Solutions since
                                    2013. From the very first day, I am amazed to see its healthy work culture,
                                    professional management, and client-centric approach. I have learned new things from
                                    my colleagues as well as seniors. I must say, if you’re ready to boost your career
                                    in a leading IT company, InheritX is a right choice for you!</p>
                                <img class="rounded-circle" src="assets/images/testimonial/Krish.jpg" alt="testimonial">
                                <div class="w-100 float-left d-flex justify-content-center employee_name">Krish Hinduja
                                </div>
                                <div class="w-100 float-left d-flex justify-content-center employee_designation">-
                                    Business Development Manager
                                </div>
                            </div>
                            <div class="item carousel-item text-center">
                                <p class="w-100 float-left text-center">I am working at InheritX Solutions since three
                                    years. During this time, I've found that the company follows every standard
                                    proecedures with the utmost transparency and client-centric approach. What I like
                                    the most about this company is employee-friendly practices and healthy competition
                                    that motivate all of us to give our best.</p>
                                <img class="rounded-circle" src="assets/images/testimonial/dhwanik.jpg" alt="testimonial">
                                <div class="w-100 float-left d-flex justify-content-center employee_name">Dhwanik Gandhi
                                </div>
                                <div class="w-100 float-left d-flex justify-content-center employee_designation">-
                                    Senior Android Developer
                                </div>
                            </div>
                            <div class="item carousel-item text-center">
                                <p class="w-100 float-left text-center">I am associated with InheritX Solutions since
                                    four years. From day 1, I was being told that apart from hiring the right candidates
                                    for various positions, my major role will be to maintain the work-life balance of
                                    all employees. I am happy that I am in a company that strives by putting employees
                                    first.</p>
                                <img class="rounded-circle" src="assets/images/testimonial/vishal_patel.jpg" alt="testimonial">
                                <div class="w-100 float-left d-flex justify-content-center employee_name">Vishal Patel
                                </div>
                                <div class="w-100 float-left d-flex justify-content-center employee_designation">-
                                    Admin Executive
                                </div>
                            </div>
                        </div>
                        <!-- Carousel controls -->
                        <!-- <a class="carousel-control left carousel-control-prev" href="#myCarousel" data-slide="prev">
                            <i class="fa fa-angle-left"></i>
                        </a>
                        <a class="carousel-control right carousel-control-next" href="#myCarousel" data-slide="next">
                            <i class="fa fa-angle-right"></i>
                        </a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Current Openings -->
<section class="w-100 float-left current_openings" #target>
    <div class="container">
        <div class="w-100 float-left">
            <div class="main_title d-flex justify-content-center" data-aos="zoom-in">
                <div class="line"><span>Current </span> Openings </div>
            </div>
            <div class="sub_title text-center" data-aos="zoom-in">Smart person who knows how to grab the opportunities
            </div>
        </div>
        <div class="w-100 float-left">
            <div class="row">
                <div *ngFor="let opeaning of opeanings" class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                    data-aos="flip-right">
                    <div class="blog_box">
                        <div class="designation_title_top">{{opeaning.title}}</div>
                        <div class="designation_content designation_content_space">{{opeaning.experience}}</div>
                        <div class="designation_title">Technologies:</div>
                        <div class="designation_content">{{opeaning.technology.join(', ') | truncate: 50 }}</div>
                        <div class="blog_read_more"></div>
                        <div class="w-100 float-left text-center">
                            <button (click)="onApply(opeaning.id)" class="main_btn"><a>Apply Now
                                    <img src="assets/images/chevron-right.svg"></a></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Contact detail -->
<section class="w-100 float-left contact_deatail">
    <div class="container">
        <div class="contact_deatail_content w-100 float-left text-center">
            <div class="contact_heading" data-aos="zoom-in">If you’ve any question or want to send the resume, contact
                our HR department.</div>
            <div class="contact_content" data-aos="zoom-in">Email: {{contactInfo?.hr_email}}</div>
            <br>
            <div class="contact_content mb-5" data-aos="zoom-in">Phone:
                 079-40025021
               <!-- {{contactInfo?.phone}} / {{contactInfo?.alt_phone}}</div> -->
               </div>
            <button routerLink="/contact-us" class="main_btn text-uppercase" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">Contact
                Us</button>
        </div>
    </div>
</section>
<iframe src="https://inheritx.kekahire.com/api/embedjobs/60db7a0a-1cce-4ad3-b1ae-7e9e67169d99" frameborder="0" height="100%" width="100%"></iframe>

<section class="current_openings">
    <div class="modal fade" id="myModal" role="dialog">
        <div class="modal-dialog modal-lg modal-xl">

            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-body">
                    <div class="top_modal_header float-left w-100">
                        <div class="modal-header">
                            <button id="modalClose" type="button" class="close" data-dismiss="modal">&times;</button>
                            <h4 class="modal-title">{{careerDetails?.career.title}}</h4>
                        </div>
                        <div class="title_bottom_text">
                            Position: {{careerDetails?.career.openings}} | Experience:
                            {{careerDetails?.career.experience}} | Location: {{careerDetails?.career.location}}
                        </div>
                    </div>
                    <div class="inner_modal_section float-left w-100">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="row">
                                <div class="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                                    <div class="modal_left_content float-left w-100">
                                        <!-- <div class="modal_small_title">
                                            Summary
                                        </div>
                                        <div class="modal_description">
                                            Designer will be creating design solutions which will have a high visual
                                            impact & prepares visual presentations by designing art and copy layouts.
                                            End-to-end brand campaigns, designing logos, brochures, graphics, digital
                                            marketing materials, creating
                                            artwork, InDesign, Illustrator, Photoshop, Print Design, Communication
                                            Skills, Visualization. Expertise in mobile application designing.
                                        </div> -->
                                        <div class="modal_small_title">
                                            Roles and Resposibilities
                                        </div>
                                        <ul class="modal_ul float-left w-100"
                                            *ngFor="let role of careerDetails?.career.roles">
                                            <li *ngIf="role;else noRoles">{{role}}</li>
                                            <ng-template #noRoles>
                                                No Roles Found
                                            </ng-template>
                                        </ul>
                                        <div class="modal_small_title">
                                            Requirements
                                        </div>
                                        <ul *ngFor="let requirement of careerDetails?.career.requirements"
                                            class="modal_ul float-left w-100">
                                            <li *ngIf="requirement;else noRequirement">{{requirement}}</li>
                                            <ng-template #noRequirement>
                                                No Requirements Found
                                            </ng-template>
                                        </ul>

                                    </div>
                                </div>
                                <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                                    <div class="modal_right_content float-left w-100">
                                        <div class="modal-header">
                                            <h4 class="modal-title">APPLY FOR
                                                {{careerDetails?.career.title | uppercase}}</h4>
                                        </div>
                                        <div class="form_section">
                                            <form [formGroup]="careerForm" (ngSubmit)="onSubmit()">
                                                <div class="form-group">
                                                    <label for="pwd">Name</label>
                                                    <input type="text" class="form-control" id="pwd"
                                                        (keyup)="checkWhiteSpace($event)" formControlName="name">
                                                    <span class="errorMessage"
                                                        *ngIf="careerForm.controls.name.touched && careerForm.controls.name.errors">
                                                        <span *ngIf="careerForm.controls.name.errors.required">
                                                            Name is required
                                                        </span>
                                                    </span>
                                                </div>
                                                <div class="form-group">
                                                    <label for="email">Email Address</label>
                                                    <input type="email" class="form-control" id="email"
                                                        formControlName="email">
                                                    <span class="errorMessage"
                                                        *ngIf="careerForm.controls.email.touched && careerForm.controls.email.errors">
                                                        <span *ngIf="careerForm.controls.email.errors.required">
                                                            Email is required
                                                        </span>
                                                        <span *ngIf="careerForm.controls.email.errors.pattern">
                                                            Please enter a valid email.
                                                        </span>
                                                    </span>
                                                </div>
                                                <div class="form-group">
                                                    <label for="phone">Contact No</label>
                                                    <input [OnlyNumber]="true" type="text" class="form-control"
                                                        id="phone" formControlName="phone">
                                                    <span class="errorMessage"
                                                        *ngIf="careerForm.controls.phone.touched && careerForm.controls.phone.errors">
                                                        <span *ngIf="careerForm.controls.phone.errors.required">
                                                            Phone is required
                                                        </span>
                                                        <span *ngIf="careerForm.controls.phone.errors.minlength">
                                                            Phone must be minimum 10 digits
                                                        </span>
                                                        <span *ngIf="careerForm.controls.phone.errors.maxlength">
                                                            Phone must be maximum 15 digits
                                                        </span>
                                                    </span>
                                                </div>
                                                <div class="form-group">
                                                    <label for="email">Upload Resume</label>
                                                    <div class="custom-file">
                                                        <input type="file" class="custom-file-input"
                                                            id="resume" name="filename" #myFile
                                                            (change)="onFileSelect($event)">
                                                        <input type="hidden" name="fileHidden"
                                                            formControlName="resume" />
                                                        <label class="custom-file-label"
                                                            for="resume">{{fileName | truncate: 25}}</label>
                                                    </div>
                                                </div>


                                                <div class="row mb-2">
                                                    <div class="col-xl-3 col-lg-3 col-md-2 col-sm-2 col-3">
                                                        <input type="text" class="w-100 text-center" disabled
                                                            value="{{x}}" />
                                                    </div>
                                                    <label>+</label>
                                                    <div class="col-xl-3 col-lg-3 col-md-2 col-sm-2 col-3">
                                                        <input type="text" class="w-100 text-center" disabled
                                                            value="{{y}} " />
                                                    </div>
                                                    <label>=</label>
                                                    <div class="col-xl-4 col-lg-4 col-md-3 col-sm-3 col-4">
                                                        <input [OnlyNumber]="true" formControlName="userResult"
                                                            type="text" class="w-100 text-center" value="" />

                                                    </div>
                                                    <div class="col-lg-12">
                                                        <span class="errorMessage"
                                                            *ngIf="careerForm.controls.userResult.touched && careerForm.controls.userResult.errors">
                                                            <span
                                                                *ngIf="careerForm.controls.userResult.errors.maxlength">
                                                                Max length should be 4 digits
                                                            </span>
                                                            <span
                                                                *ngIf="careerForm.controls.userResult.errors.required">
                                                                Please enter the result
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>

                                                <div class="modal-footer p-0 pt-3">
                                                    <button type="submit" class="main_btn">Submit</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>