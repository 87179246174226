<!-- About US Banner -->
<section class="w-100 float-left about_us_page">
  <div class="container">
    <div class="w-100 float-left">
      <h1 class="banner_title text-center">About Us</h1>
      <h2 class="banner_sub_title text-center">Our Only Aim - Make Clients Happy!</h2>
    </div>
  </div>
</section>
<section class="w-100 float-left">
    <div class=" container">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb ">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">About Us</li>
            </ol>
        </nav>
    </div>
</section>

<!-- About US page about -->
<section class="w-100 float-left about_we">
  <div class="container">
    <div class="main_title d-flex justify-content-center" data-aos="zoom-in">
      <h2 class="line"><span>Who</span> We Are </h2>
    </div>

    <p class="text-center" data-aos="zoom-in">
      Since 2011, excellent customer service is not just a phrase, but a core value of InheritX Solutions. Developing
      high-quality solutions in web and mobile app domains is a way of life at InheritX for over eight years. Our
      client-centric approach and no compromise on performance have made us an undisputed leader in mobile app, web,
      game, and digital marketing.
    </p>
    <p class="text-center mb-0" data-aos="zoom-in">
      At InheritX, our aim remains the same since inception- make and keep our clients happy. This is the reason why we
      have a large clientele across different industry sectors worldwide. Our happy clients include startups, SMEs, and
      even a few of the Fortune 500 companies. Our in-house team of experienced developers makes it possible to
      transform your creative concepts into innovative IT solutions.
    </p>
  </div>
</section>

<!-- Time Line -->
<section class="w-100 float-left timeline_section">
  <div class="container">
    <div class="main_title d-flex justify-content-center" data-aos="zoom-in">
      <h2 class="line"><span>Our Exciting </span>Journey So Far </h2>
    </div>
    <h3 class="text-center sub_title" data-aos="zoom-in">
      A small team of experienced and dedicated IT professionals has found InheritX Solutions back in 2011. Since then,
      we have never looked back in providing high-quality, result-driven, advanced technology solutions to our esteemed
      clients.
    </h3>

    <div class="w-100 text-center" data-aos="fade-down">
      <img src="assets/images/inx-logo.png" class="timeline_logo" alt="Inheritx Solutions - Web and Mobile App Development Company"/>
    </div>

    <!-- 2011 -->
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
        <div class="timeline_years" data-aos="fade-down">
          <h2 class="linear-wipe">2011</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 left_timeline" data-aos="flip-left" data-aos-easing="ease-in-sine">
        <div class="timeline_title text-right">Born of a Star</div>
        <p class="text-right">We started with a small team of enthusiastic techies.</p>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 right_timeline" data-aos="flip-right" data-aos-easing="ease-in-sine">
        <img src="assets/images/about_us/Born-of-a-Star.png" class="img-fluid" alt="2011" />
      </div>
    </div>


    <!-- 2012 -->
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
        <div class="timeline_years" data-aos="fade-down">
          <h2 class="linear-wipe">2012</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 left_timeline" data-aos="flip-left" data-aos-easing="ease-in-sine">
        <img src="assets/images/about_us/Steady_Growth.png" class="img-fluid" alt="2012" />
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 right_timeline" data-aos="flip-right" data-aos-easing="ease-in-sine">
        <div class="timeline_title text-left">Steady Growth</div>
        <p class="text-left">Our small setup turned into one of the fastest-growing IT companies. We registered InheritX
          Solutions in NASSCOM, IGDA, GESIA, and other reputed groups of organizations.</p>
      </div>
    </div>
    <!-- 2013 -->
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 text-center">
        <div class="timeline_years" data-aos="fade-down">
          <h2 class="linear-wipe">2013</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 left_timeline" data-aos="flip-left" data-aos-easing="ease-in-sine">
        <div class="timeline_title text-right">Rise in Style</div>
        <p class="text-right">Shifted to a new office and the employee strength became 50</p>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 right_timeline" data-aos="flip-right" data-aos-easing="ease-in-sine">
        <img src="assets/images/about_us/Rise-in-Style.png" class="img-fluid" alt="2013" />
      </div>
    </div>
    <!-- 2014 -->
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
        <div class="timeline_years" data-aos="fade-down">
          <h2 class="linear-wipe">2014</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 left_timeline" data-aos="flip-left" data-aos-easing="ease-in-sine">
        <img src="assets/images/about_us/International-Footprints.png" class="img-fluid" alt="2014" />
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 right_timeline" data-aos="flip-right" data-aos-easing="ease-in-sine">
        <div class="timeline_title text-left">International Footprints</div>
        <p class="text-left">Established offices in the USA, Canada, and Australia</p>
      </div>
    </div>
    <!-- 2015 -->
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
        <div class="timeline_years" data-aos="fade-down">
          <h2 class="linear-wipe">2015</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 left_timeline" data-aos="flip-left" data-aos-easing="ease-in-sine">
        <div class="timeline_title text-right">Started gaining Recognition</div>
        <p class="text-right">Brought massive changes in business policy to serve our clients better, started digital
          marketing services, and voted as the Best Mobile Application Development Company by GESIA.</p>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 right_timeline" data-aos="flip-right" data-aos-easing="ease-in-sine">
        <img src="assets/images/about_us/Started-gaining-Recognition.png" class="img-fluid" alt="2015" />
      </div>
    </div>
    <!-- 2016 -->
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
        <div class="timeline_years" data-aos="fade-down">
          <h2 class="linear-wipe">2016</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 left_timeline" data-aos="flip-left" data-aos-easing="ease-in-sine">
        <img src="assets/images/about_us/Milestone-Year.png" class="img-fluid" alt="2016" />
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 right_timeline" data-aos="flip-right" data-aos-easing="ease-in-sine">
        <div class="timeline_title text-left">Milestone Year</div>
        <p class="text-left">Crossed the milestone of 100+ employees and $1million+ business on Upwork</p>
      </div>
    </div>
    <!-- 2017 -->
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
        <div class="timeline_years" data-aos="fade-down">
          <h2 class="linear-wipe">2017</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 left_timeline" data-aos="flip-left" data-aos-easing="ease-in-sine">
        <div class="timeline_title text-right">New Horizons</div>
        <p class="text-right">Started assisting startups as their development partner and started developing high-end
          solutions in the niche technology segments- IoT and BLE along with AR/VR.</p>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 right_timeline" data-aos="flip-right" data-aos-easing="ease-in-sine">
        <img src="assets/images/about_us/New-Horizons.png" class="img-fluid" alt="2017" />
      </div>
    </div>
    <!-- 2018 -->
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
        <div class="timeline_years" data-aos="fade-down">
          <h2 class="linear-wipe">2018</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 left_timeline" data-aos="flip-left" data-aos-easing="ease-in-sine">
        <img src="assets/images/about_us/Leading-Outsourcing-Agency.png" class="img-fluid" alt="2018" />
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 right_timeline" data-aos="flip-right" data-aos-easing="ease-in-sine">
        <div class="timeline_title text-left">Leading Outsourcing Agency</div>
        <p class="text-left">Attended MWCA LA and represented InheritX Solutions as a leading global outsourcing agency
        </p>
      </div>
    </div>
    <!-- 2019 -->
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
        <div class="timeline_years" data-aos="fade-down">
          <h2 class="linear-wipe">2019</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 left_timeline" data-aos="flip-left" data-aos-easing="ease-in-sine">
        <div class="timeline_title text-right">And The Journey Goes On</div>
        <p class="text-right">Celebrating 8 Years of Successful Accomplishment of Project across Different Industry
          Sectors and expanded services to AI and Bitcoin. We also started working on Indoor Navigation projects and
          Sports Data Analytics applications. </p>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 right_timeline" data-aos="flip-right" data-aos-easing="ease-in-sine">
        <img src="assets/images/about_us/And-The-Journey-Goes-On.png" class="img-fluid" alt="2019" />
      </div>
    </div>
    <!-- Continue -->
    <div class="row">
      <div class="col-md-12 text-center">
        <div class="timeline_years" data-aos="fade-down">
          <h2 class="linear-wipe"> Continue...</h2>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- About Company -->
<section class="w-100 float-left about_company">
  <div class="container">
    <div class="main_title d-flex justify-content-center" data-aos="zoom-in">
      <h2 class="line"><span>About</span> Company</h2>
    </div>
    <h3 class="sub_title text-center w-100 float-left" data-aos="zoom-in">Leaders in providing Mobile, Web, Game and Internet Marketing solutions.</h3>

    <div class="w-100 float-left">
      <div class="row">
        <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12" data-aos="fade-down">
          <div class="about_title text-xl-right text-center">Our Vision</div>
          <p class="about_large_text text-xl-right text-center">
            To be recognized as leaders in quality services and developing relationships that make a positive difference
            in our customer’s lives.
          </p>
          <p class="about_text text-xl-right text-center">
            We provide enterprise-grade design and development services to our clients to boost the productivity of
            their business across the world with a vision to become the most reliable offshore partner.
          </p>
        </div>
        <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 text-center" data-aos="fade-up">
          <img src="assets/images/about_us/about_company.jpg" class="img-fluid" alt="about company">
        </div>
        <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 pt-xl-0 pt-lg-2" data-aos="fade-down">
          <div class="about_title text-xl-left text-center">Our Mission</div>
          <p class="about_large_text text-xl-left text-center">
              Since inception, InheritX Solutions has set many milestones by offering quality IT solutions and services that are capable of bringing disruptive changes in the corporate world.
          </p>
          <p class="about_text text-xl-left text-center">
            We are striving for offering superior quality solutions that CLIENTS prefer for their customers, EMPLOYEES
            feel pride of, CUSTOMERS appreciate, and INVESTORS choose to get long-term returns.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Testinomials -->
 <section class="w-100 float-left testimonials_section">
    <div class="container">
        <div class="main_title d-flex justify-content-center" data-aos="zoom-in">
            <h2 class="line"><span>Our</span> Testimonials </h2>
        </div>
        <h3 class="sub_title text-center w-100 float-left">Every service we provide, adds a new member into our dear
            family.
        </h3>
        <div class="services_slider_main testimonials_slider_main">
            <div *ngIf="testimonials" class="our-services slider new_testi" id="services_slider" data-aos="zoom-in">
                <div class="mx-2 pt-5 slide float-left" *ngFor="let testimonial of testimonials?.testimonial">
                    <div class="testimonial ">
                        <div class="testimonial_quotes"><img src="assets/images/about_us/bubble.png" alt="bubble"/></div>
                        {{testimonial.content | truncate: 199 }}
                    </div>
                    <div class="media">
                        <div class="media-left d-flex">
                            <img [src]="testimonial.feature_image" alt="testimonial user">
                        </div>
                        <div class="media-body">
                            <div class="overview">
                                <div class="name"><b>{{testimonial.title}}</b></div>
                                <div class="details">{{testimonial.designation}}</div>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
            <button class='MS-left leftRs'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="#060828" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-chevron-left">
                    <polyline points="15 18 9 12 15 6"></polyline>
                </svg></button>
            <button class='MS-right rightRs'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="#060828" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-chevron-right">
                    <polyline points="9 18 15 12 9 6"></polyline>
                </svg>
            </button>
        </div>

        <!-- Video Testimonials---->
       <div class="w-100 float-left mt-3">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="testimonials_video_slider_main" id="testimonials_video">
                        <div id="youtube-videos" *ngIf="testimonials?.youtubeTestimonial"
                            class="youtube_video our-services slider MS-content" data-aos="zoom-in">
                            <div *ngFor="let t of testimonials?.youtubeTestimonial" class="item slide float-left">
                                <div class="video-btn">
                                    <div class="testimonial_video_box">
                                        <img [src]="t.feature_image" class="w-100" alt="testimonial">
                                        <a><img src="assets/images/about_us/youtube.svg" alt="youtube"></a>
                                    </div>
                                    <div class="media-body">
                                        <div class="overview pr-0 pt-4">
                                            <div class="name text-center"><b>{{t.title}}</b></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button class='leftRs leftRs1'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="#060828" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-chevron-left">
                                <polyline points="15 18 9 12 15 6"></polyline>
                            </svg></button>
                        <button class='rightRs rightRs1'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="#060828" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-chevron-right">
                                <polyline points="9 18 15 12 9 6"></polyline>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Modal -->
 <div class="modal fade bd-example-modal-lg" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                 <!-- 16:9 aspect ratio  -->
                 <div class="embed-responsive embed-responsive-16by9">
                    <iframe id="testimonial-video" width="560" height="315" frameborder="0" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</div>