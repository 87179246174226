import { Component, OnInit, Inject, OnDestroy, ViewChild, ElementRef, AfterContentChecked, PLATFORM_ID, AfterViewInit } from '@angular/core';
import { SharedService } from '../../../shared/shared.service'
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppConstant } from '../../../../app/app.constant';
import { CommonFunction } from '../../../shared/common-function';
import { ActivatedRoute, Router } from '@angular/router';
import { MetaService } from 'src/app/shared/canonical.service';
import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import { ScriptLoaderService } from 'src/app/shared/services/script-loader.service';

declare var $: any;
declare var AOS: any;

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.css']
})
export class CareerComponent extends CommonFunction implements OnInit, AfterContentChecked, OnDestroy, AfterViewInit {
  opeanings: Array<any>;
  gallery: Array<any>;
  contactInfo: any;
  careerForm: FormGroup;
  result: number;
  x: number;
  y: number;
  fileName: string = "";
  selectedFile: File = null;
  selectedPdf: FormData;
  careerDetails: { career: any };
  socialLinks = AppConstant.socialLinks;
  @ViewChild('myFile') myFile: ElementRef;


  constructor(
    @Inject(SharedService) private sharedService: SharedService,
    private fb: FormBuilder,
    @Inject(ActivatedRoute) private activatedRoute: ActivatedRoute,
    private router: Router,
    private metaService: MetaService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private scriptLoader: ScriptLoaderService,
    private viewportScroller: ViewportScroller,) {
    super();

    this.careerForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(AppConstant.emailValidationPattern)]],
      phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(15)]],
      resume: ['', Validators.required],
      userResult: ['', [Validators.required, Validators.maxLength(4)]]
    });
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
      $("#myModal").on("hidden.bs.modal", function () {
        $('body').css('overflow-y', 'scroll');
      });

      AOS.init();
      $('#myCarousel').carousel({
        interval: 5000,
        pause: "false"
      });
     
    }
    this.activatedRoute.fragment.subscribe((fragment: string) => {
      if (fragment) {
        this.viewportScroller.scrollToAnchor(fragment);
      } else {
        this.viewportScroller.scrollToPosition([0, 0])
      }
    });
    let href = this.router.url;
    this.metaService.createCanonicalURL(href);
    this.sharedService.showSpinner();
    this.activatedRoute.data.subscribe((data) => {
      this.sharedService.hideSpinner();
      const res = data.career;
      this.gallery = res[0].gallery;
      this.scriptLoader.load({
        name: 'fancybox',
        src: 'assets/js/jquery.fancybox.min.js',
        loaded: false
      }).then((script) => {
        this.applyEffect();
      })
      this.opeanings = res[1].career;
      this.contactInfo = res[2].contactinfo;
    });

    var input = document.getElementsByTagName('input')[3];
    input.onclick = () => {
      this.myFile.nativeElement.value = null;
    };
  }

  ngAfterContentChecked() {
    if (isPlatformBrowser(this.platformId)) {
      AOS.refresh();
    }
  }

  ngAfterViewInit() {
  }



  checkWhiteSpace(event) {
    if (event.keyCode == 32 && this.careerForm.controls.name.value.length == 1) {
      this.careerForm.controls['name'].setValue('', { onlySelf: true });
    }
  }

  onFileSelect(e) {
    const demoString: string = this.myFile.nativeElement.value;
    if (!demoString.match(/\.(pdf|doc|docx|odf)$/i)) {
      this.careerForm.controls['resume'].setValue('');
      return this.sharedService.showError('Error!,Allowed file types are doc,docx,pdf,odf');
    } else {
      this.fileName = demoString.replace("C:\\fakepath\\", "");

      this.selectedFile = <File>e.target.files[0];
      const fd = new FormData();
      fd.set('resume', this.selectedFile, this.selectedFile.name);
      this.careerForm.patchValue({
        "resume": fd
      });
    }
  }

  generateFormData() {
    let formData: FormData = new FormData();
    formData.append('name', this.careerForm.controls['name'].value),
      formData.append('email', this.careerForm.controls['email'].value),
      formData.append('phone', this.careerForm.controls['phone'].value),
      formData.append('resume', this.selectedFile);

    return formData;
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: "smooth" });
  }

  applyEffect() {
    $(".fancybox").fancybox({
      openEffect: "none",
      closeEffect: "none"
    });

    $(".zoom").hover(function () {

      $(this).addClass('transition');
    }, function () {

      $(this).removeClass('transition');
    });
  }

  onApply(id) {
    this.careerForm.reset();
    this.fileName = "";

    this.careerForm.controls['userResult'].setValue('');
    this.generaterandomNumber();

    this.sharedService.getCareerDetails(id).subscribe((res: any) => {
      $('#myModal').modal('show');
      $('body').css('overflow-y', 'hidden');
      this.careerDetails = res;
    });
  }

  onSubmit() {
    this.sharedService.removeError();
    if (this.careerForm.invalid) {
      return this.sharedService.showError('Please fill all the required fields');
    }
    else if (!this.careerForm.controls['userResult'].value) {
      return this.sharedService.showError('Please submit the answer');
    }
    else if (this.result != this.careerForm.controls['userResult'].value) {
      this.careerForm.controls['userResult'].setValue('');
      this.generaterandomNumber();
      return this.sharedService.showError('Please submit the correct answer');
    }
    else {
      $("#modalClose").click();
      this.myFile.nativeElement.value = null;
      const data: FormData = this.generateFormData();

      this.sharedService.postCareerFormData(data).subscribe((res: any) => {

        if (res.status == 1) {
          this.sharedService.showSuccess(res.message);
        } else if (res.status == 0) {
          this.sharedService.showError(res.message);
        }
      });
    }
  }


  generaterandomNumber() {
    this.x = Math.trunc(Math.random() * 5);
    this.y = Math.trunc(Math.random() * 5);
    this.result = this.x + this.y;
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      $("#modalClose").click();
    }
    // this.metaService.removeCanonicalLink();
  }

}
