import { Component, OnInit, AfterContentChecked, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { MetaService } from 'src/app/shared/canonical.service';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
declare var AOS: any;

@Component({
  selector: 'app-hiring-modal',
  templateUrl: './hiring-modal.component.html',
  styleUrls: ['./hiring-modal.component.css']
})
export class HiringModalComponent implements OnInit, AfterContentChecked, OnDestroy {

  constructor(
    private router: Router,
    private metaService: MetaService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
      AOS.init();
    }
    let href = this.router.url;
    this.metaService.createCanonicalURL(href);
  }

  ngAfterContentChecked() {
    if (isPlatformBrowser(this.platformId)) {
      AOS.refresh();
    }
  }
  ngOnDestroy() {
    // this.metaService.removeCanonicalLink();
  }
}
