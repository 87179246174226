export const environment = {
  production: true,
   Base_URL: 'https://admin.inheritx.com/wp-json/api/', // Live
 // Base_URL: 'http://inheritxdev.net/inx_new/qa/wp-json/api/',  // Qa,
  // Base_URL: 'http://inheritxdev.net/inx_new/dev/wp-json/api/',  //Dev

   APP_URL: 'https://www.inheritx.com', // Live
  // APP_URL: 'https://inheritx.com/Angular'  //Angular,

  //APP_URL: 'http://inheritxdev.net/inx_new/frontend-qa/' // Qa

};
