import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, AfterContentChecked, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';


import { MetaService } from 'src/app/shared/canonical.service';
declare var AOS: any;

@Component({
  selector: 'app-why-inheritx',
  templateUrl: './why-inheritx.component.html',
  styleUrls: ['./why-inheritx.component.css']
})
export class WhyInheritxComponent implements OnInit, AfterContentChecked, OnDestroy {

  constructor(
    private router: Router,
    private metaService: MetaService,
    @Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
      AOS.init();
    }
    let href = this.router.url;
    this.metaService.createCanonicalURL(href);
  }
  ngAfterContentChecked() {
    if (isPlatformBrowser(this.platformId)) {
      AOS.refresh();
    }
  }

  ngOnDestroy() {
    // this.metaService.removeCanonicalLink();
  }
}
