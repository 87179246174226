import { DOCUMENT } from '@angular/common';
import { Injectable, Optional, RendererFactory2, ViewEncapsulation, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
   providedIn: 'root'
})
 
export class MetaService { 
   constructor(@Inject(DOCUMENT) private dom,private rendererFactory: RendererFactory2,) { }
    
    createCanonicalURL(url?:string) {
        let canURL = url == undefined ? this.dom.URL :environment.APP_URL +  url;
        let link: HTMLLinkElement = this.dom.createElement('link');
        link.setAttribute('rel', 'canonical');
        this.dom.head.appendChild(link);
        link.setAttribute('href', canURL);
    }
//     removeCanonicalLink() {
//         try {
//             const renderer = this.rendererFactory.createRenderer(this.dom, {
//                 id: '-1',
//                 encapsulation: ViewEncapsulation.None,
//                 styles: [],
//                 data: {}
//             });
//             const canonical = document.querySelector("link[rel='canonical']")
//             const head = this.dom.head;

//             if (head === null) {
//                 throw new Error('<head> not found within DOCUMENT.');
//             }
//             if (!!canonical) {
//                 renderer.removeChild(head, canonical);
//             }
//         } catch (e) {
//             console.error('Error within linkService : ', e);
//         }
// }
} 