import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedService } from '../shared.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CommonFunction } from '../common-function';
import { AppConstant } from '../../../app/app.constant';
import { isPlatformBrowser } from '@angular/common';
import { distinctUntilChanged } from 'rxjs/operators';
declare var AOS: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent extends CommonFunction implements OnInit {
  form: FormGroup;
  budgets: Array<any> = ['Select Your Budget*', '<$5000', '$5000 - $25,000', 'Above $25000'];
  locations: Array<any>;
  year = new Date().getFullYear();
  contactInfo: any;
  x: number;
  y: number;
  result: number;
  socialLinks = AppConstant.socialLinks;
  currentUrl: string;

  constructor(
    @Inject(FormBuilder) private fb: FormBuilder,
    @Inject(SharedService) private sharedService: SharedService,
    @Inject(Router) private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(ActivatedRoute) private activatedRoute: ActivatedRoute,
  ) {
    super();
    router.events
      .pipe(
        distinctUntilChanged((previous: any, current: any) => {
          if (current instanceof NavigationEnd) {
            this.currentUrl = current.url;
            return previous.url === current.url;
          }
          return true;
        })).subscribe((x: any) => {
          //   ga('set', 'page', x.url);
          //  ga('send', 'pageview')
        });
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      AOS.init();
    }
    this.form = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(AppConstant.emailValidationPattern)]],
      country: ['', Validators.required],
      phone: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(15)]],
      project_budget: ['', Validators.required],
      project_type: [''],
      project_details: ['', Validators.required],
      project_nda: 0,
      userResult: ['']
    });
    this.setBudgetValue();
    this.generateRandomNumber();

    this.sharedService.getLocationsData().subscribe((data: any) => {
      this.locations = data.locations.reverse();
      this.contactInfo = data.contactinfo;
    });

    //Reset form after route change
    this.router.events.subscribe(() => {
      this.form.reset();
      this.setBudgetValue();
    });

    this.sharedService.getPhoneAndEmail().subscribe((data: any) => {
      this.contactInfo = data.contactinfo;
    });
  }


  onSubmit() {
    this.sharedService.removeError();
    if (this.form.invalid) {
      return this.sharedService.showError('Please fill all the required fields');
    } else if (this.form.controls.project_budget.value == 'Select Your Budget*') {
      return this.sharedService.showError('Please fill all the required fields');
    } else if (!this.form.controls['userResult'].value) {
      return this.sharedService.showError('Please submit the answer');
    } else if (this.result != this.form.controls['userResult'].value) {
      return this.sharedService.showError('Please submit the correct answer');
    }
    // else if (!this.form.controls.acceptTerms.value) {
    //   return this.sharedService.showError('Please accept the terms');
    // }
    const formData = this.generateFormdata(this.form.value);
    this.sharedService.postFormData(formData).subscribe((response: any) => {
      if (response) {
        this.sharedService.showSuccess(response.message);
        this.form.reset();
        this.setBudgetValue();
        this.generateRandomNumber();
        this.router.navigate(['thank-you']);
      }
    });
  }

  setBudgetValue() {
    this.form.controls['project_budget'].setValue(this.budgets[0], { onlySelf: true });
  }

  checkWhiteSpace(event) {
    if (event.keyCode == 32 && this.form.controls.name.value.length == 1) {
      this.form.controls['name'].setValue('', { onlySelf: true });
    }
  }

  generateRandomNumber() {
    this.form.controls['userResult'].setValue('');
    this.x = Math.trunc(Math.random() * 5);
    this.y = Math.trunc(Math.random() * 5);
    this.result = this.x + this.y;
  }
  onChange(e) {
    this.form.patchValue({
      project_nda: e.target.checked ? 1 : 0
    });
  }
}
