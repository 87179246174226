<div class="container">
  <div class="thankyou_main">
    <div class="thankyou_box">
      <img src="assets/images/thank-you.svg" alt="Thank You">
      <h2 class="text-animation">Thank you for visiting us! </h2>
      <p>Our consultant will get in touch with you in next 24 hours.</p>
    </div>

  </div>
</div>


<section class="w-100 float-left only-copy-right">
  <div class="container">
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 footer_copyright text-left">Copyright © {{year}} InheritX. All
        Right Reserved</div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 footer_copyright">
        <div class="footer_social_icon_section w-100 float-left d-flex justify-content-sm-end justify-content-center">
          <a *ngFor="let link of socialLinks" [href]="link.url" target="_blank">
            <div class="social_icon_box">
              <img [src]="link.src" alt="Social icon" />
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>