import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { AppConstant } from 'src/app/app.constant';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class HomeService {
  constructor(@Inject(HttpClient) private http: HttpClient) { }

  private blogs = new Subject<Array<any>>();
  blogsObservable$ = this.blogs.asObservable();

  private clients = new Subject<Array<any>>();
  clientsObservable$ = this.clients.asObservable();

  private portfolio = new Subject<Array<any>>();
  portfolioObservable$ = this.portfolio.asObservable();

  private teams = new Subject<Array<any>>();
  teamsObservable$ = this.teams.asObservable();

  private testimonial = new Subject<any>();
  testimonialObservable$ = this.testimonial.asObservable();

  sendBlogsData(data: Array<any>) {
    this.blogs.next(data);
  }

  sendClientsData(data: Array<any>) {
    this.clients.next(data);
  }

  sendPortfolioData(data: Array<any>) {
    this.portfolio.next(data);
  }

  sendTeamsData(data: Array<any>) {
    this.teams.next(data);
  }

  sendTestimonialData(data: any) {
    this.testimonial.next(data);
  }

  getContentData() {
    return this.http.get(environment.Base_URL + 'home');
  }
  getTeamData() {
    return this.http.get(environment.Base_URL + 'v1/teaminfo');
  }
}
