<!-- Services Banner -->

<section class="w-100 float-left about_us_page">
    <div class="container">
        
        <div class="w-100 float-left">
            <h1 class="banner_title text-center">Why Inheritx?</h1>
            <h2 class="banner_sub_title text-center">
                Since 2011, we’re committed to providing the best-in-class web and mobile app solutions to our corporate clients, worldwide. </h2>
        </div>
    </div>
</section>

<section class="w-100 float-left">
<div class=" container">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb ">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Why Inheritx</li>
            </ol>
        </nav>
    </div>
    </section>
<app-why-choose-inheritx></app-why-choose-inheritx>


<!-- Our Expertise -->
<section class="w-100 float-left our_platforms">
    <div class="container">
        <div class="w-100 float-left">
            <div class="main_title d-flex justify-content-center" >
                <h2 class="line"><span>What We </span> Offer? </h2>
            </div>
            <h3 class="sub_title text-center" >InheritX Solutions has an in-house team of experienced developers who can handle any size project in the domains of web, mobile app, and IoT. </h3>
        </div>
        <div class="our_platforms_section w-100 float-left">
            <div class="row ">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 our_platforms_box" >
                    <div class="expertise_box">
                        <div class="expertise_box_img spin circle"><img src="assets/images/why_us/certificate.svg" alt="certificate">
                        </div>
                        <div class="expertise_title">Certified Developers</div>
                        <div class="expertise_Text">Our skilled developers have a knack for developing futuristic solutions in web and mobile app development.</div>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 our_platforms_box" >
                    <div class="expertise_box">
                        <div class="expertise_box_img spin circle"><img src="assets/images/why_us/email.svg" alt="email"></div>
                        <div class="expertise_title">Quick Delivery</div>
                        <div class="expertise_Text">At InheritX, we ensure timely project completion through our scheduled work approach. We also provide regular updates regarding the progress of your project.</div>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 our_platforms_box" >
                    <div class="expertise_box">
                        <div class="expertise_box_img spin circle"><img src="assets/images/why_us/office.svg" alt="office"></div>
                        <div class="expertise_title">State-of-the-art Infrastructure</div>
                        <div class="expertise_Text">Excellent digital infrastructure can hold a key to success for an IT company. At InheritX, we have advanced infrastructure equipped with servers, cutting-edge tools, and latest gadgets.
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 our_platforms_box" >
                    <div class="expertise_box">
                        <div class="expertise_box_img spin circle"><img src="assets/images/why_us/receipt.svg" alt="receipt"></div>
                        <div class="expertise_title">Competitive Pricing</div>
                        <div class="expertise_Text">Our flexible pricing models can readily fit your ever-changing business needs. We offer transparent pricing without any hidden charges.</div>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 our_platforms_box" >
                    <div class="expertise_box">
                        <div class="expertise_box_img spin circle"><img src="assets/images/why_us/innovation.svg" alt="innovation"></div>
                        <div class="expertise_title">Domain Expertise</div>
                        <div class="expertise_Text">When it comes to domain insight and hands-on experience of using the latest tools, our developers always remain updated with modern trends and advancements.</div>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 our_platforms_box" >
                    <div class="expertise_box">
                        <div class="expertise_box_img spin circle"><img src="assets/images/why_us/customer-service.svg" alt="customer service">
                        </div>
                        <div class="expertise_title">IT Consultancy</div>
                        <div class="expertise_Text">We provide top-notch IT consulting services along with end-to-end IT solutions. From design to deployment, and maintenance to marketing, we take care of every aspect.</div>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 our_platforms_box" >
                    <div class="expertise_box">
                        <div class="expertise_box_img spin circle"><img src="assets/images/why_us/responsive.svg" alt="responsive"></div>
                        <div class="expertise_title">Flexible Development Process</div>
                        <div class="expertise_Text">While implementing the agile methodology, we make sure that our development process remains client-centric and flexible to meet changing business needs. </div>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 our_platforms_box" >
                    <div class="expertise_box">
                        <div class="expertise_box_img spin circle"><img src="assets/images/why_us/encrypted.svg" alt="encrypted"></div>
                        <div class="expertise_title">Higher Security</div>
                        <div class="expertise_Text">Our employees and stakeholders are bound to prevent your confidential company and project data from going into the wrong hands. We give the safety of your data utmost importance.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>