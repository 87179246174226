<section class="w-100 float-left footer_section" >
    <div class="container" *ngIf="currentUrl != '/contact-us'">
        <div class="w-100 float-left contact_us">
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <div class="footer_title">Contact Information</div>
                    <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div class="footer_textbox">
                                    <input type="text" name="nameee" placeholder="Name*" formControlName="name"
                                        (keyup)="checkWhiteSpace($event)" pattern="^[^ ].+[^ ]$"/>
                                    <span class="errorMessage"
                                        *ngIf="form.controls.name.errors && (form.controls.name.touched && form.controls.name.invalid)">
                                        <span *ngIf="form.controls.name.errors?.required">Name is required</span>
                                    </span>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div class="footer_textbox">
                                    <input type="email" name="email" placeholder="Email*" formControlName="email" />
                                    <span class="errorMessage"
                                        *ngIf="form.controls.email.errors && (form.controls.email.touched && form.controls.email.invalid)">
                                        <span *ngIf="form.controls.email.errors.required">Email is required.</span>
                                        <span *ngIf="form.controls.email.errors.pattern">Please enter a valid
                                            email.</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div class="footer_textbox">
                                    <input type="text" name="country" placeholder="Country*" formControlName="country" >
                                    <span class="errorMessage"
                                        *ngIf="form.controls.country.errors && (form.controls.country.touched && form.controls.country.invalid)">
                                        <span *ngIf="form.controls.country.errors.required">Country is required</span>
                                    </span>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div class="footer_textbox">
                                    <input type="text" name="phone" placeholder="Phone*" formControlName="phone">
                                    <span class="errorMessage"
                                        *ngIf="form.controls.phone.errors && (form.controls.phone.touched && form.controls.phone.invalid)">
                                        <span *ngIf="form.controls.phone.errors.required">Phone is required</span>
                                        <span *ngIf="form.controls.phone.errors.pattern">Phone should be numeric</span>
                                        <span
                                            *ngIf="!form.controls.phone.errors.pattern && (form.controls.phone.errors.minlength || form.controls.phone.errors.maxlength)">
                                            <span *ngIf="form.controls.phone.errors.minlength">
                                                Phone must be minimum 10 digits
                                            </span>
                                            <span *ngIf="form.controls.phone.errors.maxlength">
                                                Phone must be maximum 15 digits
                                            </span>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div class="footer_textbox">
                                    <input type="text" name="project_type" placeholder="Project Type"
                                        formControlName="project_type">
                                    <span class="errorMessage"
                                        *ngIf="form.controls.project_type.errors && (form.controls.project_type.touched && form.controls.project_type.invalid)">
                                        Project Type is required
                                    </span>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div class="footer_textbox">
                                    <select onchange="this.className=this.options[this.selectedIndex].className"
                                        class="" formControlName="project_budget" [ngStyle]="{'color':this.form.controls['project_budget'].value === this.budgets[0] ? '#f1f1f180' : '#fff' }">
                                        <option *ngFor="let b of budgets; let i = index" class="white_option"
                                            [disabled]="i == 0">{{b}}</option>
                                    </select>
                                    <span class="errorMessage"
                                        *ngIf="form.controls.project_budget.touched && (form.controls.project_budget.invalid || (form.controls.project_budget.value == 'Select Your Budget*'))">
                                        Please select a Budget
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 col-sm-12 col-12">
                                <div class="footer_textbox">
                                    <textarea name="details" rows="3" placeholder="Brief Your Details*"
                                        formControlName="project_details" pattern="^[^ ].+[^ ]$"></textarea>
                                    <span class="errorMessage"
                                        *ngIf="form.controls.project_details.errors && (form.controls.project_details.touched && form.controls.project_details.invalid)">
                                        Details are required
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row pb-3">

                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-3 col-3"><input type="text" value={{x}}
                                    class="text-center" disabled /></div>
                            <label class="check_label">+</label>
                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-3 col-3"><input type="text" value={{y}}
                                    class="text-center" disabled /></div>
                            <label class="check_label">=</label>
                            <div [OnlyNumber]="true" class="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-5"><input
                                    type="text" class="text-center" formControlName="userResult"></div>

                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <label class="checkbox">Request Non-Disclosure Agreement for a Confidential
                                    Consultation.
                                    <input type="checkbox" formControlName="project_nda"  (change)="onChange($event)">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                        <div class="w-100 float-left d-flex justify-content-md-start justify-content-center"
                            ><button
                                class="main_btn text-uppercase" type="submit">Schedule a free
                                Consultation</button>
                        </div>
                    </form>
                </div>

                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <div class="footer_title">We’re Here!</div>

                    <!-- india -->
                    <div class="row">
                        <!-- United States  -->
                        <div *ngFor="let location of locations"
                            class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 w-100 float-left">
                            <div class="footer_sub_title"><img [src]="location.location_image" alt="location" />{{location.title}}
                            </div>
                            <div class="footer_address">
                                {{location.address1}},<br> {{location.address2}},
                                <br> {{location.country}}
                            </div>
                        </div>
                    </div>
                    <div class="w-100 float-left footer_call pb-2"><img
                            src="assets/images/footer/call.png" alt="call"/>
                        <a href="tel:+1(347) 394-0007">{{contactInfo?.phone}}</a>
                    </div>
                    <div class="w-100 float-left footer_call"><img
                            src="assets/images/footer/mail.png" alt="mail"/>
                        <a href="mailto:contact@inheritx.com">contact@inheritx.com</a>
                    </div>

                    <div class="social_icon_title">Connect With Us:</div>
                    <div class="footer_social_icon_section w-100">
                        <a *ngFor="let link of socialLinks" [href]="link.url" target="_blank">
                            <div class="social_icon_box">
                                <img [src]="link.src" alt="social icon"/>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="bottom_footer">
        Copyright © {{year}}&nbsp;<b>InheritX Solutions.&nbsp;</b> All Rights Reserved.
    </div>
</section>