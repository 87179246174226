import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class ScriptLoaderService {
    private scripts: ScriptModel[] = [];

    public load(script: ScriptModel): Promise<ScriptModel> {
        return new Promise((resolve, reject) => {
            var existingScript = this.scripts.find(s => s.name == script.name);
            // Complete if already loaded
            if (existingScript && existingScript.loaded) {
                resolve(script)
            }
            else {
                // Add the script
                this.scripts = [...this.scripts, script];

                // Load the script
                let scriptElement = document.createElement("script");
                scriptElement.innerHTML = '';
                scriptElement.type = "text/javascript";
                scriptElement.src = script.src;
                scriptElement.async = false;
                scriptElement.defer = true;

                scriptElement.onload = () => {
                    script.loaded = true;
                    resolve(script)
                };

                scriptElement.onerror = (error: any) => {
                    reject("Couldn't load script " + script.src);
                };

                document.getElementsByTagName('body')[0].appendChild(scriptElement);
            }
        });
    }
}

export interface ScriptModel {
    name: string,
    src: string,
    loaded: boolean
}