<!-- Services page about -->
<section class="w-100 float-left services_about" *ngIf="content?.category != 'node.js-development' &&
content?.category != 'angularjs-development'">
  <div class="container">
    <div class="career_content float-left w-100">
      <div class="main_title d-flex justify-content-center">
        <h2 class="line"><span>Why Choose </span> Inheritx?</h2>
      </div>
      <div>
        <span>"InheritX Solutions has gained fame as a top-notch tablet app development company in India and USA. Our
          expertise and deep understanding in building apps for iPads and Android tablets can help you add value in
          business. Our iPad app development services are designed to meet the complex business requirements and address
          a huge audience with out-of-the-box app solutions.",
          "Our customized iPad app solutions can help your business stand out from the crowd in a cost-effective way,
          and you can easily capitalize on sales opportunities while getting a higher ROI. On-time delivery and
          excellent app performance are our mainstays since inception. Our dedicated iPad app developers are capable of
          developing iPad applications across various industry verticals and segments."
        </span>
      </div>

      <div class="counter_section float-left w-100">
        <div class="row">
          <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
            <div class="white_bg_counter_box">

              <p><span class="counter-count">8</span><span class="counter_plus">+</span></p>
              <div class="counter_content">Years of experience</div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
            <div class="white_bg_counter_box">
              <p><span class="counter-count">40</span><span class="counter_plus">+</span></p>
              <div class="counter_content">Industries Served</div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
            <div class="white_bg_counter_box">
              <p><span class="counter-count">120</span><span class="counter_plus">+</span></p>
              <div class="counter_content">Development staff</div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
            <div class="white_bg_counter_box">
              <p><span class="counter-count">24</span><span class="counter_plus">/7</span></p>
              <div class="counter_content">Support</div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 offset-lg-3 offset-xl-3">
            <div class="white_bg_counter_box">
              <p><span class="counter-count">400</span><span class="counter_plus">+</span></p>
              <div class="counter_content">Verified 5 star client reviews</div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
            <div class="white_bg_counter_box">
              <p><span class="counter-count">850</span><span class="counter_plus">+</span></p>
              <div class="counter_content">Projects Delivered Successfully</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>



<section class="w-100 float-left Company_offers services_about" *ngIf="content?.category == 'node.js-development'">
  <div class="container">
    <div class="Company_offers_section float-left w-100 career_content">
      <div class="main_title d-flex justify-content-center" data-aos="zoom-in">
        <h2 class="line"><span>Why Trust Us</span> For Your Node.Js Apps?</h2>
      </div>
      <p class="float-left text-center" data-aos="zoom-in">
        We strive to hire dedicated, skilled, and highly qualified Node.JS developers capable of implementing
        applications that meet our clients' diverse business requirements. The Node.JS development team is always ready
        to tackle new challenges and provide
        the best end-to-end solutions. Partner with us to take advantage of a wide range of benefits like:
      </p>
      <ul class="w-100 float-left text-left">
        <li>
          <p><b>Fast and real-time application development</b> </p>
          <p class="mt-0">Build an open-source, fast, dynamic, and scalable application with our Node.JS real-time app
            development service. </p>
        </li>
        <li>
          <p><b>State-of-the-art development infrastructure</b></p>
          <p class="mt-0">Make use of the latest technologies and build customers centric market-ready Node.JS
            applications with Inheritx. </p>
        </li>
        <li>
          <p><b>Work with Node.JS developers With 8+ years of experience</b></p>
          <p class="mt-0">Develop robust and feature-rich Node.JS applications with our highly knowledgeable and
            experienced team of developers. </p>
        </li>
        <li>
          <p><b>Flexible development approach</b></p>
          <p class="mt-0">Scale your business with a fully transparent and measurable Node.JS development process with
            long-term commitments. </p>
        </li>
        <li>
          <p><b>24/7 Customer and Technical Support</b></p>
          <p class="mt-0">Have an expert team for continuous technical support and maintenance and provide your
            customers with minimum downtime. </p>
        </li>
      </ul>
      <div class="counter_section float-left w-100">
        <div class="row">
          <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
            <div class="white_bg_counter_box">

              <p><span class="counter-count">8</span><span class="counter_plus">+</span></p>
              <div class="counter_content">Years of experience</div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
            <div class="white_bg_counter_box">
              <p><span class="counter-count">40</span><span class="counter_plus">+</span></p>
              <div class="counter_content">Industries Served</div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
            <div class="white_bg_counter_box">
              <p><span class="counter-count">120</span><span class="counter_plus">+</span></p>
              <div class="counter_content">Development staff</div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
            <div class="white_bg_counter_box">
              <p><span class="counter-count">24</span><span class="counter_plus">/7</span></p>
              <div class="counter_content">Support</div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 offset-lg-3 offset-xl-3">
            <div class="white_bg_counter_box">
              <p><span class="counter-count">400</span><span class="counter_plus">+</span></p>
              <div class="counter_content">Verified 5 star client reviews</div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
            <div class="white_bg_counter_box">
              <p><span class="counter-count">850</span><span class="counter_plus">+</span></p>
              <div class="counter_content">Projects Delivered Successfully</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>


<section class="w-100 float-left Company_offers services_about" *ngIf="content?.category == 'angularjs-development'">
  <div class="container">
    <div class="Company_offers_section float-left w-100 career_content">
      <div class="main_title d-flex justify-content-center" data-aos="zoom-in">
        <h2 class="line"><span>Why Hire Inheritx </span>for your Angular JS Development needs?</h2>
      </div>
      <p class="float-left text-center" data-aos="zoom-in">
        Because of its numerous features and compatibility, AngularJS has become one of the most popular front-end
        development
        frameworks for producing outstanding web projects. Our expert Angular developers use the latest technology
        and resources to deliver end-to-end Angular JS development solutions.
      </p>
      <p class="float-left text-center" data-aos="zoom-in">
        Inheritx is known to provide Angular JS development services for any suitable web application with the
        help of experienced experts. Here are some of the reasons you should build your app with Inheritx's
        AngularJS mobile app development.
      </p>
      <ul class="w-100 float-left text-left">
        <li>
          <p><b>Affordable Prices</b> </p>
          <p class="mt-0">To ensure the lowest pricing in our category, Inheritx offers an optimal blend of
            cost-effective rates and exceptional AngularJS web development services.</p>
        </li>
        <li>
          <p><b>Dedicated Team of Professionals</b></p>
          <p class="mt-0">
            Web developers fluent in Angular JS and several frameworks and technologies are on hand to help us create
            high-quality business solutions for our clients.
          </p>
        </li>
        <li>
          <p><b>Enhanced Customer Experience</b></p>
          <p class="mt-0">
            For Angular JS web development, we employ the most up-to-date frameworks and technologies to give you
            scalable,
            secure, user-friendly, and creative business solutions.
          </p>
        </li>
        <li>
          <p><b>Extensibility</b></p>
          <p class="mt-0">Inheritx's Angular JS web development services are designed to be compatible and extensible to
            all your
            business requirements.Thus, you will be able to make any modifications you desire to make the web page or
            app to suit your specific needs.</p>
        </li>
        <li>
          <p><b>Flexibility</b></p>
          <p class="mt-0">Angular JS employs technology that is much more versatile as compared to HTML. Because of
            this,
            our programmers work hard to make sure you can use the full potential of single-page app creation and
            testing.</p>
        </li>
        <li>
          <p><b>Transparency & Integrity</b></p>
          <p class="mt-0">We believe that you have the best vision for your business, and this is why we respect your
            ideas.
            We maintain complete transparency by providing you with all information on the development process and
            taking your
            valuable feedback.</p>
        </li>
      </ul>
    </div>

  </div>
</section>