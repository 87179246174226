<section class="w-100 float-left">
    <div class="top_header w-100">
        <div class="container">

                <nav class="navbar navbar-expand-lg  w-100" id="navbar">
                    <a class="navbar-brand" routerLink="/"><img src="assets/images/inx-logo.svg" alt="Inheritx Solutions - Web and Mobile App Development Company"/></a>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse justify-content-sm-end" id="navbarNavDropdown">
                        <ul class="navbar-nav">
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle menu_arrow" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Company
                                </a>
                                <span aria-expanded="false" aria-haspopup="true" class="dropdown-toggle mobile_menu_arrow at-drop-down" data-toggle="dropdown"  role="button"></span>
                                <ul class="dropdown-menu submenu_list about_menu" aria-labelledby="navbarDropdownMenuLink">
                                    <li> <a routerLink="/why-inheritx" >Why Inheritx </a></li>
                                    <li> <a routerLink="/about">About US </a></li>
                                    <li> <a routerLink="/hiring-model">Hiring Model </a></li>
                                    <li> <a routerLink="/career-builder">Career </a></li>
                                    <!-- <li> <a routerLink="/blog">Blog </a></li> -->
                                </ul>
                            </li>
                            <li class="nav-item dropdown position-static">

                                <a class="nav-link dropdown-toggle menu_arrow at-drop-down" routerLink="/services" id="navbarDropdownMenuLink">
                                    Services
                                </a>
                                <span aria-expanded="false" aria-haspopup="true" class="dropdown-toggle mobile_menu_arrow at-drop-down" data-toggle="dropdown"  role="button"></span>

                                <ul class="dropdown-menu w-100 sub-menu" aria-labelledby="navbarDropdownMenuLink">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                                            <div class="w-100 float-left submenu_list_title">Mobile App Development
                                            </div>
                                            <ul class="submenu_list">
                                                <li><a routerLink="/services/iphone-app-development"> iPhone App
                                                        Development</a>
                                                </li>
                                                <li><a routerLink="/services/android-app-development"> Android App
                                                        Development</a>
                                                </li>
                                                <li><a routerLink="/services/kotlin-app-development" > Kotlin App
                                                        Development </a>
                                                </li>
                                                <li><a routerLink="/services/flutter-app-development"> Flutter App
                                                    Development</a>
                                                </li>
                                                <li><a routerLink="/services/react-native-app-development"> React Native
                                                App Development</a></li>
                                                <li><a routerLink="/services/ipad-app-development"> iPad/Tablet App
                                                        Development</a></li>
                                                <li><a routerLink="/services/xamarin-app-development"> Xamarin App
                                                        Development </a>
                                                </li>

                                                <li><a routerLink="/services/ionic-app-development"> Ionic App
                                                        Development</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                                            <div class="w-100 float-left submenu_list_title">Web Development</div>
                                            <ul class="submenu_list">
                                                <li> <a routerLink="/services/angularjs-development" >
                                                   AngularJS Development
                                                    </a></li>
                                                <li> <a routerLink="/services/reactjs-development" >
                                                   ReactJS Development
                                                    </a>
                                                </li>
                                                <li> <a routerLink="/services/node.js-development">
                                                    Node.js Development
                                                </a>
                                            </li>
                                                <li> <a routerLink="/services/php-development">
                                                    PHP Development </a></li>
                                                <li> <a routerLink="/services/java-development">
                                                   JAVA Development </a>
                                                </li>
                                                <li> <a routerLink="/services/wordpress-development">
                                                   WordPress Development </a>
                                                </li>
                                                <li> <a routerLink="/services/magento-development" >
                                                    Magento Development
                                                    </a>
                                                </li>

                                            </ul>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                                            <div class="w-100 float-left submenu_list_title">Other Services</div>
                                            <ul class="submenu_list">
                                                <li><a routerLink="/services/aws-services" >AWS Services </a></li>
                                                <li><a routerLink="/services/google-cloud-services" >Google Cloud
                                                        Services</a></li>
                                                <!-- <li><a routerLink="/services/windows-services">Windows Services</a></li> -->
                                                <li><a routerLink="/services/pwa-development" >PWA Development </a></li>
                                                <li><a routerLink="/services/seo-services" >SEO Services</a></li>
                                                <li><a routerLink="/services/iot-development" >IOT Development </a></li>
                                            </ul>
                                        </div>
                                        <!-- <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                            <div class="w-100 float-left submenu_list_title">Solutions </div>
                                            <ul class="submenu_list">
                                                <li> <a routerLink="/services/image-editing">Image Editing </a></li>
                                                <li> <a routerLink="/services/on-diamond-app">On Dimand App </a></li>
                                                <li> <a routerLink="/services/indoor-navigation">Indoor Navigation </a>
                                                </li>
                                                <li> <a routerLink="/services/utility-base-solution">Utility Base
                                                        Solution </a></li>
                                                <li> <a routerLink="/services/sports-solution">Beacon / Sports Solution
                                                    </a></li>
                                            </ul>
                                        </div> -->
                                    </div>
                                </ul>
                            </li>

                            <!-- <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle menu_arrow" href="#" id="navbarDropdownMenuLink"
                                    role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Solutions
                                </a>
                                <ul class="dropdown-menu submenu_list about_menu"
                                    aria-labelledby="navbarDropdownMenuLink">
                                    <li> <a routerLink="/services/image-editing">Image Editing </a></li>
                                    <li> <a routerLink="/services/on-demand-app">On Demand App </a></li>
                                    <li> <a routerLink="/services/indoor-navigation">Indoor Navigation </a>
                                    </li>
                                    <li> <a routerLink="/services/utility-base-solution">Utility Base
                                            Solution </a></li>
                                    <li> <a routerLink="/services/sports-solution">Beacon / Sports Solution
                                        </a></li>
                                </ul>
                            </li> -->
                            <li class="nav-item dropdown position-static">
                                <a class="nav-link dropdown-toggle menu_arrow" routerLink="/hire-resources" id="navbarDropdownMenuLink" >
                                    Hire Us
                                </a>
                                <span aria-expanded="false" aria-haspopup="true" class="dropdown-toggle mobile_menu_arrow at-drop-down" data-toggle="dropdown"  role="button"></span>
                                <ul class="dropdown-menu w-100" aria-labelledby="navbarDropdownMenuLink">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                                            <ul class="submenu_list">
                                                <li><a routerLink="/hire-resources/hire-iphone-developer" >Hire iPhoneDeveloper</a></li>
                                                <li><a routerLink="/hire-resources/hire-android-developer" >Hire Android Developer</a></li>
                                                <li><a routerLink="/hire-resources/hire-react-native-developer">Hire React Native Developer</a></li>
                                                <li><a routerLink="/hire-resources/hire-flutter-developer" >Hire Flutter Developer</a></li>
                                                <li><a routerLink="/hire-resources/hire-phonegap-developer" >Hire Phonegap Developer</a></li>
                                            </ul>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                                            <ul class="submenu_list">
                                                    <li><a routerLink="/hire-resources/hire-angularjs-developer" >Hire AngularJS Developer</a></li>
                                                    <li><a routerLink="/hire-resources/hire-react-developer" >Hire ReactJS Developer</a></li>
                                                    <li><a routerLink="/hire-resources/hire-node-js-developer" >Hire Node.js Developer</a></li>
                                                    <li><a routerLink="/hire-resources/hire-php-developer" >Hire PHP Developer</a></li>
                                                    <li><a routerLink="/hire-resources/hire-laravel-developer" >Hire Laravel Developer</a></li>
                                            </ul>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                                            <ul class="submenu_list">

                                                <li><a routerLink="/hire-resources/hire-python-developer" >Hire Python Developer</a> </li>
                                                <li><a routerLink="/hire-resources/hire-wordPress-developer" >Hire WordPress Developer</a></li>
                                                <li><a routerLink="/hire-resources/hire-asp-developer" >Hire ASP Developer</a> </li>

                                                <!-- <li><a routerLink="/hire-resources/hire-magento-developer">Hire Magento
                                                        Developer</a>
                                                </li>
                                                <li><a routerLink="/hire-resources/hire-codeigniter-developer">Hire CodeIgniter
                                                        Developer</a></li> -->
                                            </ul>
                                        </div>
                                        <!-- <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                            <ul class="submenu_list">

                                                <li><a routerLink="/hire-resources/hire-yii-developer">Hire Yii Developer</a>
                                                </li>
                                                <li><a routerLink="/hire-resources/hire-cakephp-developer">Hire CakePHP
                                                        Developer</a>
                                                </li>
                                            </ul>
                                        </div> -->
                                    </div>
                                </ul>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link my-portfolio" routerLink="/blog" >Blog</a>
                            </li>
                            <li class="nav-item">
                            <a class="nav-link my-portfolio" routerLink="/portfolios">Our Portfolio</a>
                            </li>

                            <li class="nav-item menu_contact_btn" >
                            <a class="nav-link my-portfolio d-lg-none" routerLink="/contact-us">Contact Us</a>

                            <a class="main_btn d-none d-lg-flex" routerLink="/contact-us" style="text-decoration: none;
                            padding-top: 10px;">Contact Us</a>

                            </li>
                        </ul>
                    </div>
                </nav>

        </div>
    </div>
</section>