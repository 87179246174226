export interface ISitemapTag {
    customUrl: string;
    title: string | null;
    description: string | null;
    keywords: string | null;
}
export class AppConstant {
    public static servicesCategory = [
        { "category": "iphone", "url_config": "iphone-app-development", "platform": "mobile" },
        { "category": "android", "url_config": "android-app-development", "platform": "mobile" },
        { "category": "kotlin", "url_config": "kotlin-app-development", "platform": "mobile" },
        { "category": "ipad", "url_config": "ipad-app-development", "platform": "mobile" },
        { "category": "xamarin", "url_config": "xamarin-app-development", "platform": "mobile" },
        { "category": "flutter", "url_config": "flutter-app-development", "platform": "mobile" },
        { "category": "react", "url_config": "react-native-app-development", "platform": "mobile" },
        { "category": "ionic", "url_config": "ionic-app-development", "platform": "mobile" },

        { "category": "angularjs", "url_config": "angularjs-development", "platform": "web" },
        { "category": "reactjs", "url_config": "reactjs-development", "platform": "web" },
        { "category": "php", "url_config": "php-development", "platform": "web" },
        { "category": "java", "url_config": "java-development", "platform": "web" },
        { "category": "wordpress", "url_config": "wordpress-development", "platform": "web" },
        { "category": "magento", "url_config": "magento-development", "platform": "web" },
        { "category": "node", "url_config": "node.js-development", "platform": "web" },

        { "category": "aws", "url_config": "aws-services", "platform": "other" },
        { "category": "google_cloud", "url_config": "google-cloud-services", "platform": "other" },
        { "category": "windows", "url_config": "windows-services", "platform": "other" },
        { "category": "pwa", "url_config": "pwa-development", "platform": "other" },
        { "category": "seo", "url_config": "seo-services", "platform": "other" },
        { "category": "iot", "url_config": "iot-development", "platform": "other" },

        { "category": "image_editing", "url_config": "image-editing", "platform": "solutions" },
        { "category": "on_diamond_app", "url_config": "on-diamond-app", "platform": "solutions" },
        { "category": "indoor_navigation", "url_config": "indoor-navigation", "platform": "solutions" },
        { "category": "utility_base_solution", "url_config": "utility-base-solution", "platform": "solutions" },
        { "category": "sports_solution", "url_config": "sports-solution", "platform": "solutions" },

        { "category": "iphone", "url_config": "hire-iphone-developer", "platform": "solutions" },
        { "category": "android", "url_config": "hire-android-developer", "platform": "solutions" },
        { "category": "hire", "url_config": "hire-hire-developer", "platform": "solutions" },
        { "category": "angularjs", "url_config": "hire-angularjs-developer", "platform": "solutions" },
        { "category": "node", "url_config": "hire-node-js-developer", "platform": "solutions" },
        { "category": "laravel", "url_config": "hire-laravel-developer", "platform": "solutions" },
        { "category": "phonegap", "url_config": "hire-phonegap-developer", "platform": "solutions" },
        { "category": "react", "url_config": "hire-react-developer", "platform": "solutions" },
        { "category": "python", "url_config": "hire-python-developer", "platform": "solutions" },
        { "category": "wordPress", "url_config": "hire-wordPress-developer", "platform": "solutions" },
        { "category": "magento", "url_config": "hire-magento-developer", "platform": "solutions" },
        { "category": "codeigniter", "url_config": "hire-codeigniter-developer", "platform": "solutions" },
        { "category": "asp", "url_config": "hire-asp-developer", "platform": "solutions" },
        { "category": "ionic", "url_config": "hire-ionic-developer", "platform": "solutions" },
        { "category": "yii", "url_config": "hire-yii-developer", "platform": "solutions" },
        { "category": "cakephp", "url_config": "hire-cakephp-developer", "platform": "solutions" }
    ];
    public static emailValidationPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/;
    public static socialLinks = [
        { src: 'assets/images/social/facebook-letter-logo.svg', url: 'https://www.facebook.com/InheritxSolutions/' },
        { src: 'assets/images/social/twitter.svg', url: 'https://twitter.com/inheritx' },
        { src: 'assets/images/social/linkedin-logo.svg', url: 'https://www.linkedin.com/company/inheritx-solutions-pvt-ltd/' },
        { src: 'assets/images/social/instagram.svg', url: 'https://www.instagram.com/inheritxsolutions/' }
    ];
    public static blogSocialLinks = [
        { src: 'assets/images/social/facebook-letter-logo.svg', url: 'https://www.facebook.com/InheritxSolutions/' },
        { src: 'assets/images/social/twitter.svg', url: 'https://twitter.com/inheritx' },
        { src: 'assets/images/social/linkedin-logo.svg', url: 'https://www.linkedin.com/company/inheritx-solutions-pvt-ltd/' },
    ];

    public static seoSitemap: ISitemapTag[] = [
        {
            customUrl: '/',
            keywords: 'Mobile Application Development Company, Web Development Services, Mobile App Development Company, Web Design and Development Company',
            description: 'We are Web & Mobile App Development Company from India & USA offering Custom Web and Mobile App Development services across the globe.',
            title: 'Top Web and Mobile App Development Company | Inheritx '
        },
        {
            customUrl: '/images/logo.png',
            keywords: 'Mobile Application Development Company, Web Development Services, Mobile App Development Company, Web Design and Development Company',
            description: 'We are Web & Mobile App Development Company from India & USA offering Custom Web and Mobile App Development services across the globe.',
            title: 'Top Web and Mobile App Development Company | Inheritx '
        },
        {
            customUrl: '/contact-us',
            keywords: 'Mobile Application Development Company, Web Development Services, Mobile App Development Company, Web Design and Development Company',
            description: 'Get in touch for your Web and Mobile App Development requirements. We provide complete Web and Mobile App Development solutions with 24/7 Support.',
            title: 'Contact us for Mobile and Web App Development Services'
        },
        {
            customUrl: '/thank-you',
            keywords: '',
            description: '',
            title: ''
        },
        {
            customUrl: '/blog',
            keywords: '',
            description: 'Subscribe us and stay updated with our latest Web and Mobile App Development news and information at Inheritx Solutions.',
            title: 'Web and Mobile App development Blog | Inheritx'
        },
        {
            customUrl: '/portfolios',
            keywords: '',
            description: 'Let’s have a look on our successful Web Development services and Mobile Apps we have built for our clients across the globe.',
            title: 'Our Portfolio for Mobile Apps & Web Development Services'
        },
        {
            customUrl: '/why-inheritx',
            keywords: '',
            description: 'We provide Affordable, Effective and Creative Web and Mobile App Development Services for a variety of businesses with 24/7 technical assistance. Contact us now!',
            title: 'Best Web and Mobile App Solutions | Inheritx'
        },
        {
            customUrl: '/about',
            keywords: 'Web Design Company, Web Development Company, Mobile App Development Company, Web Developers',
            description: 'Our Mission is to keep our clients happy by continuously providing quality service of Web and Mobile App Development. Our Developers help to transform your creative concepts into innovative IT solutions.',
            title: 'Web Design, Web Development and Mobile App Development Company | Inheritx'
        },
        {
            customUrl: '/hiring-model',
            keywords: 'Dedicated Resource Engagement, Fixed Price Model, Time & Material, Hourly Price Model',
            description: 'InheritX Solutions offers the best hiring models to address ever-changing business needs and the growing challenges of the corporate sector.',
            title: 'Hiring Model | InheritX Solution'
        },
        {
            customUrl: '/career-builder',
            keywords: '',
            description: 'Be a part of Inheritx Solutions and Boost Your Career with Leading IT Company.',
            title: 'Join Inheritx Solutions and Make Your Future Bright!'
        },
        {
            customUrl: '/services',
            keywords: 'App Development Services, Web Development Services, Web Development Service Provider, Android App Development Service, Custom Web Development Service Provider',
            description: 'Looking for a reliable custom web and mobile app development company? InheritX provides outsource web & app development solutions. Contact us now!',
            title: 'Web & Mobile App Development Services USA, UK | InheritX Solution'
        },
        {
            customUrl: '/services/iphone-app-development',
            keywords: 'iPhone Application Development Company, Best iPhone App Development Company, Custom iOS App Development, iPhone Application Development Services, iPhone App Development Services',
            description: 'Inheritx is a leading iPhone App Development Company from India and USA offering high-quality & customized iOS App Development Services.',
            title: 'iPhone App Development Company, iOS Application Development Services',
        },
        {
            customUrl: '/services/android-app-development',
            keywords: 'Android Application Development, Android App Development Services, Android Application Development Company, Android Mobile App Development Company',
            description: 'We are a leading Android Mobile App Development Company in the USA and India offering end-to-end Android App Development Service to our clients across the globe. Call Now!',
            title: 'Top Android App Development Company | Inheritx',
        },
        {
            customUrl: '/services/flutter-app-development',
            keywords: 'Flutter App Development, Flutter App Development Company, Flutter Mobile App Development',
            description: 'Looking for a best Flutter App Development Services? InheritX Solutions is a renowned Flutter App Development Company offering Flutter Application Development Services worldwide. ',
            title: 'Flutter App Development Company, Hire Flutter Developers | InheritX',
        },
        {
            customUrl: '/services/kotlin-app-development',
            keywords: 'Kotlin App Development, Kotlin Android App Development',
            description: 'Looking for a best Kotlin Android App Development Services? Hire Kotlin App developers from the Top Kotlin App Development Company based in India and USA.',
            title: 'Top Kotlin Application Development Company | InheritX',
        },
        {
            customUrl: '/services/xamarin-app-development',
            keywords: 'Xamarin App Development Company, Xamarin Mobile App Development, Xamarin Application Development Company',
            description: 'Get a cross platform app solutions with our Xamarin mobile app development services for your business at reasonable rates. Hire Xamarin Developers now!',
            title: ' Xamarin Mobile App Development Company, Hire Xamarin Developers | Inheritx',
        },
        {
            customUrl: '/services/ionic-app-development',
            keywords: 'Ionic Mobile App Development Company, Ionic App Development Company, Ionic Application Development, Ionic Hybrid Mobile App Development',
            description: 'Top Ionic Mobile App Development Company based in India and USA provides the best Ionic Mobile App Development Services to create highly interactive apps.',
            title: 'Ionic Hybrid Mobile App Development Company | InheritX',
        },
        {
            customUrl: '/services/ipad-app-development',
            keywords: 'iPad App Development Company, iPad App Development Services, iPad Application Development Company, Tablet App Development Company',
            description: 'InheritX is a top-rated iPad App Development Company. We offer iPad App Development Services for enterprises. We are also a leading Tablet App Development Company.',
            title: 'iPad App Development Company, Tablet App Developers | InheritX',
        },
        {
            customUrl: '/services/react-native-app-development',
            keywords: 'React Native App Development Company, React Native Development Services, React Native App Development Services, React Native Mobile App Development',
            description: 'InheritX is a leading React Native App Development Company that offers best-in-class React Native App Development Services across various industry sectors.',
            title: 'React Native App Development Company, Hire React Native Developers',
        },
        {
            customUrl: '/services/angularjs-development',
            keywords: 'InheritX Technologies is a top-notch AngularJS Application Development Company in India and USA. We offer enterprise-grade, custom AngularJS development services.',
            description: 'InheritX Technologies is a top-notch AngularJS Application Development Company in India and USA. We offer enterprise-grade, custom AngularJS development services.',
            title: 'AngularJS Web Application Development Company | InheritX'
        },
        {
            customUrl: '/services/reactjs-development',
            keywords: 'ReactJS Development Company, ReactJS Development Services, ReactJS Application Development',
            description: 'We are the best ReactJS Development Services Provider. Our team of expert developers offers top ReactJS Application Development Services globally.',
            title: 'ReactJS App Development Company | InheritX',
        },
        {
            customUrl: '/services/php-development',
            keywords: 'PHP Web Application Development Services, PHP Web Application Development, PHP Web Development Services, Custom PHP Web Development Services, PHP Web Application Development Company',
            description: 'Inheritx is a leading PHP Web Development Company based in India &USA provides the best-in-class PHP Web Application Development Services for Start-ups to large enterprises.',
            title: 'Custom PHP Web Application Development Company | InheritX',
        },
        {
            customUrl: '/services/java-development',
            keywords: 'Java Application Development, Java Software Development Company, Java Software Development Services, Java Application Development Services',
            description: 'Inheritx is a prominent Java Software Development Company offering custom Java Software & Applications Development Services for various industry sectors globally.',
            title: 'Java Software, Application Development Company | InheritX',
        },
        {
            customUrl: '/services/wordpress-development',
            keywords: 'WordPress Development Company, WordPress Development Services, WordPress Web Development Company, WordPress Website Development Company, WordPress Website Development Services',
            description: 'We are a leading WordPress Development Company based in India & USA provides the cost effective Custom WordPress Website Development Services to our clients.',
            title: 'Top WordPress Web Development Company India | InheritX',
        },
        {
            customUrl: '/services/magento-development',
            keywords: 'Magento Ecommerce Development Company, Magento Website Development Services',
            description: 'InheritX Solutions is a leading Magento eCommerce Development Company that has certified Magento Developers & provide top Magento Web Development Services.',
            title: 'Magento E-Commerce Website Development Company | InheritX',
        },
        {
            customUrl: '/services/node.js-development',
            keywords: 'Node.JS Development Company, Node.JS Application Development, Node.JS Web Application Development',
            description: 'InheritX offers top-notch Node.JS Application Development Services to offer scalable and real-time web and mobile apps. Hire Node.JS Developers.',
            title: 'Node.JS Application Development Company | InheritX',
        },
        {
            customUrl: '/services/aws-services',
            keywords: 'AWS Database Migration Service, AWS Server Migration Service, AWS Cloud Services, Amazon Cloud Computing Services',
            description: 'We are a leading AWS Cloud Services provider. Get AWS Database Migration Service, AWS Server Migration Service with our experienced AWS professionals.',
            title: 'Amazon Cloud Computing Services by Inheritx',
        },
        {
            customUrl: '/services/pwa-development',
            keywords: 'PWA Development Services, PWA Development Company, Progressive Web App Development Company, Progressive Web App Development Services',
            description: 'We are a leading Progressive Web App Development Company offering Custom & Responsive PWA Development Services.',
            title: 'Progressive Web App (PWA) Development Company | Inherix',
        },
        {
            customUrl: '/services/seo-services',
            keywords: 'SEO Consulting Services, Affordable SEO Services, eCommerce SEO Services, Affordable SEO Services for Small Business',
            description: 'Improve search visibility with our SEO Consulting Services, eCommerce SEO Services. Get Affordable SEO Services for Small Business now!',
            title: 'Affordable SEO Services for Small Business & eCommerce | Inheritx',
        },
        {
            customUrl: '/services/google-cloud-services',
            keywords: 'Google Cloud App Development, Google Cloud Services, GCP Services',
            description: 'Inheritx Solutions provides the best GCP Services which includes Google Cloud App Development, Google Cloud Services in India & USA by certified professionals.',
            title: 'GCP Services by certified professional at Inheritx ',
        },
        {
            customUrl: '/services/iot-development',
            keywords: 'IoT App Development Company, IoT Software Development Company',
            description: 'Top IoT Apps and Software Development Company build the Fast, Reliable IoT Apps & IoT Software. Contact us for the Complete IoT Services.',
            title: 'IoT Software & App Development Company | Inheritx',
        },
        {
            customUrl: '/hire-resources',
            keywords: 'Hire Web Developer, Hire App Developer, Hire Dedicated Web Developer',
            description: 'Hire Experienced Web and Mobile App Developer with 24/7 Support from InheritX. We have a team of Certified Web and Mobile App Developers for your customised Web and App Solutions.',
            title: 'Hire Dedicated Web and App Developer India - InheritX'
        },
        {
            customUrl: '/hire-resources/hire-iphone-developer',
            keywords: 'Hire iPhone Application Developer, Hire Dedicated iPhone Developer, Hire iPhone App Developer, Hire iOS App Developer',
            description: 'Have an iPhone App idea and looking for iPhone App Developer? At InheritX we provide dedicated certified iPhone Application Developer. Contact us now!',
            title: 'Hire iPhone Application Developer from Inheritx',
        },
        {
            customUrl: '/hire-resources/hire-android-developer',
            keywords: 'Hire Android App Programmer, Hire Android App Developer, Hire Android Application Developer, Hire Dedicated Android App Developer',
            description: 'Hire Android App Developer to develop enterprise-grade and feature-rich Android apps. Hire dedicated Android app Programmer to your app concept into reality.',
            title: 'Hire dedicated Android App Developer',
        },
        {
            customUrl: '/hire-resources/hire-php-developer',
            keywords: 'Hire Dedicated PHP Developer, Hire Dedicated PHP Programmers, Hire PHP Web Developer, PHP Application Developer',
            description: 'Looking for a Dedicated PHP Developer for your Web and Application Projects?  Get experienced PHP Web and Application Programmers across the globe from Inheritx.',
            title: 'Hire Dedicated PHP Web & Application Developers from India',
        },
        {
            customUrl: '/hire-resources/hire-angularjs-developer',
            keywords: 'AngularJS Full Stack Developer, Top AngularJS Developers, Hire Dedicated AngularJS Developer, Hire AngularJS Developer',
            description: 'Looking for AngularJS Developers? Hire our experienced & dedicated AngularJS Developers for enterprise-grade, custom AngularJS development services from Inheritx.',
            title: 'Hire Top AngularJS Developers | Inheritx',
        },
        {
            customUrl: '/hire-resources/hire-node-js-developer',
            keywords: 'Hire Node.JS Developer, Hire Senior Node.JS Developers, Node.JS Backend Developer',
            description: 'Hire our Senior Node.JS Developers, Node.JS Backend Developer to build the scalable and real-time web and mobile app. Visit now & Hire Node.JS Developer.',
            title: 'Hire Senior Node.JS Developers from Inheritx',
        },
        {
            customUrl: '/hire-resources/hire-wordPress-developer',
            keywords: 'Hire Wordpress Website Developer, Hire Wordpress Developer, Hire Wordpress Web Developer, Hire Expert Wordpress Developers',
            description: 'Hire WordPress developer from our in-house team of certified professionals to develop customised WordPress website. Hire the Expert Wordpress Developers now!',
            title: 'Hire Expert Wordpress Web Developers from Inheritx',
        },
        {
            customUrl: '/hire-resources/hire-python-developer',
            keywords: 'Hire Python Backend Developer, Hire Remote Python Developer, Hire Python Software Developer, Hire Senior Python Developer, Hire Python Programmer',
            description: 'Hire Python Backend Developer, Python Software Developer from our Inheritx’s flexible hiring models. Hire Senior Python Programmer on an hourly or monthly basis across the globe.',
            title: 'Hire Remote Senior Python Developer from India',
        },
        {
            customUrl: '/hire-resources/hire-asp-developer',
            keywords: 'Hire ASP Developer, Hire Dot Net Developer, Hire Dot Net Programmers, Dot Net Developer',
            description: 'Are you in search of the best ASP Development Company to Hire Dot Net Developers? We are offering flexible models to hire ASP Dot Net Developers across the globe.',
            title: 'Hire ASP Developer, Dot Net Developer from India',
        },
        {
            customUrl: '/hire-resources/hire-react-developer',
            keywords: 'Hire ReactJS Developers, Hire React Developers React Js Developer',
            description: 'Hire dedicated Hire ReactJS Developers as per the duration and complexity of your project. Get top-class ReactJS solutions by our expert ReactJS Developers across the globe.',
            title: 'Hire Experienced ReactJS Developers from India',
        },
        {
            customUrl: '/hire-resources/hire-phonegap-developer',
            keywords: 'Phonegap Developer, Hire Phonegap Developer, Hire Phonegap Programmer',
            description: 'Hire Phonegap developers for custom Phonegap App development with a native experience. We offer flexible engagement models to hire Phonegap programmers.',
            title: 'Hire Phonegap Developer | Inheritx',
        },
        {
            customUrl: '/hire-resources/hire-laravel-developer',
            keywords: 'Hire Laravel Programmers, Hire Expert Laravel Developer, Hire Dedicated Laravel Developer',
            description: 'InheritX Solutions facilitates you to Hire Expert Laravel Developers for your web project. Hire certified Laravel Programmers from our in-house team of developers.',
            title: 'Hire Expert Laravel Developers and Programmers',
        },
        {
            customUrl: '/hire-resources/hire-react-native-developer',
            keywords: 'Hire React Native Developer, React Native Developer, Hire React Native App Developers',
            description: 'Looking for React Native Developer? Hire our in-house certified and experienced React Native App Developers to build the React Native apps for Android, iOS, and web platforms.',
            title: 'Hire Best React Native App Developers | InheritxX',
        },
        {
            customUrl: '/hire-resources/hire-flutter-developer',
            keywords: 'Hire Flutter Developer, Hire Flutter App Developers',
            description: 'Looking for Flutter Developers? Hire our in-house experienced Flutter App Developers who provides end-to-end Flutter app development services across the globe.',
            title: 'Hire Dedicated Flutter App Developers | Inheritx',
        }
    ];


}