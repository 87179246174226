import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { isPlatformServer } from '@angular/common';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Observable, of, from, forkJoin} from 'rxjs';
// import { forkJoin } from "rxjs/observable/forkJoin";
// import 'rxjs/add/observable/forkJoin';

import { tap, map } from 'rxjs/operators';
import { SharedService } from 'src/app/shared/shared.service';
// import 'rxjs/add/observable/forkJoin';
// import {forkJoin} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CareerResolver implements Resolve<any> {
    constructor(
        private sharedService: SharedService,
        @Inject(PLATFORM_ID) private platformId,
        private transferState: TransferState
    ) { }

    resolve(
        route: ActivatedRouteSnapshot
    ): Observable<any> {

        const BLOG_KEY = makeStateKey<any>('career');
        if (this.transferState.hasKey(BLOG_KEY)) {
            const course = this.transferState.get<any>(BLOG_KEY, null);
            this.transferState.remove(BLOG_KEY);
            return of(course);
        } else {
            return forkJoin([
                this.sharedService.getGalleryImages(),
                this.sharedService.getCurrentOpenings(),this.sharedService.getPhoneAndEmail()])
                .pipe(
                    tap((resp: any) => {
                        if (isPlatformServer(this.platformId)) {
                            this.transferState.set(BLOG_KEY, resp);
                        }
                    })
                )
            // ]);
            // return forkJoin([
            //     this.sharedService.getGalleryImages(),
            //   this.sharedService.getCurrentOpenings()])
            //         .catch(error => {
            //             if(error.status === 404) {
            //               //  this.router.navigate(['subscription-create']);
            //             }
            //             return Observable.throw(error);
            //         })
        }
    }
}
