import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralBlogsComponent } from './general-blogs/general-blogs.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { PaginatorComponent } from './paginator/paginator.component';
import { WhyChooseInheritxComponent } from './why-choose-inheritx/why-choose-inheritx.component';
import { RouterModule } from '@angular/router';
import { FaqComponent } from './faq/faq.component';
import { OnlyNumber } from '../onlyNumber.directive';
import { TruncatePipe } from './custom.pipe';



@NgModule({
  declarations: [GeneralBlogsComponent, PaginatorComponent, WhyChooseInheritxComponent, FaqComponent, OnlyNumber,TruncatePipe],
  imports: [
    CommonModule,
    NgxPaginationModule,
    RouterModule
  ],
  exports: [GeneralBlogsComponent, PaginatorComponent, WhyChooseInheritxComponent, FaqComponent, OnlyNumber,TruncatePipe]
})
export class SharedModule { }
