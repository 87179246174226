import { Component, OnInit, Input } from '@angular/core';
import { ServicesData } from 'src/app/main/services/servicesJsonData';
@Component({
  selector: 'app-why-choose-inheritx',
  templateUrl: './why-choose-inheritx.component.html',
  styleUrls: ['./why-choose-inheritx.component.css']
})
export class WhyChooseInheritxComponent implements OnInit {

  constructor() { }
  @Input() content: any;

  ngOnInit() {
    //   $('.counter-count').each(function () {
    //     $(this).prop('Counter', 0).animate({
    //       Counter: $(this).text()
    //     }, {
    //         duration: 5000,
    //         easing: 'swing',
    //         step: function (now) {
    //           $(this).text(Math.ceil(now));
    //         }
    //       });
    //   });

    //   if (!this.content) {
    //     this.content = ServicesData.defaultInheritX[0].why_inheritx;
    //   }
    // }
  }
}
