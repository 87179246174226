import { Component, OnInit, AfterViewInit } from '@angular/core';
// import { WINDOW} from '@ng-toolkit/universal';
import { Router, NavigationEnd } from '@angular/router'; // import Router and NavigationEnd
import { from, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { SharedService } from './shared/shared.service';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { MetatagsService } from './shared/metatags.service';
import { AppConstant } from './app.constant';
// declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  platform: boolean;
  metaSub: Subscription
  constructor(
    public router: Router,
    // @Inject(WINDOW) private window: Window,
    @Inject(SharedService) private SharedService: SharedService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string,
    private metatagsService: MetatagsService
  ) {
    // console.log(this.window);
    this.SharedService.showSpinner();
    const platform = isPlatformBrowser(platformId) ?
      this.SharedService.hideSpinner() : this.SharedService.showSpinner();
    this.metaSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.setMetaTags(event);
      }
    });
  }

  ngOnInit() {
    // this.SharedService.showSpinner();

  }

  private setMetaTags(event: NavigationEnd) {
    const item = AppConstant.seoSitemap.find((i) => event.urlAfterRedirects === i.customUrl);
    if (item) {
      if (item.title) this.metatagsService.updateTitle(item.title);

      this.metatagsService.updateTags([
        item.description ? { name: 'description', content: item.description } : null,
        item.keywords ? { name: 'keywords', content: item.keywords } : null,
      ]);
    }
  }

  ngAfterViewInit() {
    //this.SharedService.hideSpinner();
  }



}
