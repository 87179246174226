import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { isPlatformServer } from '@angular/common';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Observable, of, from } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { SharedService } from 'src/app/shared/shared.service';

@Injectable({
    providedIn: 'root'
})
export class BlogResolver implements Resolve<any> {
    constructor(
        private sharedService: SharedService,
        @Inject(PLATFORM_ID) private platformId,
        private transferState: TransferState
    ) { }

    resolve(
        route: ActivatedRouteSnapshot
    ): Observable<any> {

       // const slug: any = route.params.id;
        const BLOG_KEY = makeStateKey<any>('blog');
        if (this.transferState.hasKey(BLOG_KEY)) {
            const course = this.transferState.get<any>(BLOG_KEY, null);
            this.transferState.remove(BLOG_KEY);
            return of(course);
        } else {
            return this.sharedService.getBlogPages()
                .pipe(
                    tap((resp: any) => {
                        if (isPlatformServer(this.platformId)) {
                            this.transferState.set(BLOG_KEY, resp);
                        }
                    })
                );
        }
    }
}
