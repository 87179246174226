import { Injectable, Inject } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from "rxjs/operators";
import { SharedService } from './shared/shared.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(@Inject(SharedService) private sharedService: SharedService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.sharedService.showSpinner();
        const copiedReq = req.clone({
            // headers: req.headers.set('Access-Control-Allow-Headers', 'Access-Control-Request-Headers')
        });
        return next.handle(copiedReq).pipe(
            tap(evt => {
                if (evt instanceof HttpResponse) {
                    this.sharedService.hideSpinner();
                }
            })
        )
    }
}
