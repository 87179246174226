import { Component, OnInit, Inject, OnDestroy, AfterContentChecked, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, ValidatorFn, FormControl } from '@angular/forms';
import { SharedService } from '../../shared/shared.service';
import { CommonFunction } from '../../shared/common-function';
import { Title } from '@angular/platform-browser';
import { AppConstant } from '../../app.constant';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router'; // import Router and NavigationEnd
import { from } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { MetaService } from '../../shared/canonical.service';
import { isPlatformBrowser } from '@angular/common';
const trimValidator: ValidatorFn = (control: FormControl) => {
  if (control.value.startsWith(' ')) {
    return {
      'trimError': { value: 'control has leading whitespace' }
    };
  }
  if (control.value.endsWith(' ')) {
    return {
      'trimError': { value: 'control has trailing whitespace' }
    };
  }

  return null;
};

declare var $: any;
declare var AOS: any;
declare let ga: Function;
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent extends CommonFunction implements OnInit, AfterContentChecked, OnDestroy {
  form: FormGroup;
  budgets = ['Select Your Budget*', '<$5000', '$5000 - $25,000', 'Above $25000'];
  clients: any;
  isFormValid: boolean = false;
  message: string;
  year = new Date().getFullYear();
  locations: Array<any>;
  contactInfo: any;
  x: number;
  y: number;
  result: number;
  socialLinks = AppConstant.socialLinks;

  constructor(
    @Inject(FormBuilder) private fb: FormBuilder,
    @Inject(SharedService) private sharedService: SharedService,
    private router: Router,
    @Inject(ActivatedRoute) private activatedRoute: ActivatedRoute,
    private metaService: MetaService, private titleService: Title,
    @Inject(PLATFORM_ID) private platformId: Object

  ) {
    super();
    router.events
      .pipe(
        distinctUntilChanged((previous: any, current: any) => {
          if (current instanceof NavigationEnd) {
            return previous.url === current.url;
          }
          return true;
        })).subscribe((x: any) => {
          //   ga('set', 'page', x.url);
          //  ga('send', 'pageview')
        });
  }
  newTitle: "contact us";

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
      AOS.init();
    }
    let href = this.router.url;
    this.metaService.createCanonicalURL(href);
    this.form = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(AppConstant.emailValidationPattern)]],
      country: ['', Validators.required],
      phone: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(15)]],
      project_budget: ['', Validators.required],
      project_type: [''],
      project_details: ['', Validators.required],
      project_nda: 0,
      userResult: ['']
    });
    if (isPlatformBrowser(this.platformId)) {
      this.setBudgetValue();
    }
    this.generateRandomNumber();
    this.sharedService.showSpinner();
    this.activatedRoute.data.subscribe((data) => {
      this.sharedService.hideSpinner();
      const res = data.contactus;
      this.clients = res[0].clients;
      this.locations = res[1].locations.reverse();
      this.contactInfo = res[1].contactinfo;
    });



    // this.sharedService.getLocationsData().subscribe((data: any) => {

    // });


    // //Get phone & Email
    // this.sharedService.getPhoneAndEmail().subscribe(((data: any) => {
    //   this.contactInfo = data.contactinfo;
    // }));
  }

  ngAfterContentChecked() {
    this.createSlick();
    AOS.refresh();
    // $(window).on('load', this.createSlick);
  }

  createSlick() {
    $(".customer-logos").not('.slick-initialized').slick({
      autoplay: true,
      arrows: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplaySpeed: 1500,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2
          }
        }
      ]
    });
  }

  onSubmit() {
    this.sharedService.removeError();
    if (this.form.invalid) {
      return this.sharedService.showError('Please fill all the required fields');
    } else if (this.form.controls.project_budget.value == 'Select Your Budget*') {
      return this.sharedService.showError('Please fill all the required fields');
    } else if (!this.form.controls['userResult'].value) {
      return this.sharedService.showError('Please submit the answer');
    } else if (this.result != this.form.controls['userResult'].value) {
      return this.sharedService.showError('Please submit the correct answer');
    }

    // else if (!this.form.controls.acceptTerms.value) {
    //   return this.sharedService.showError('Please accept the terms');
    // }
    const formData = this.generateFormdata(this.form.value);
    this.sharedService.postFormData(formData).subscribe((response: any) => {
      if (response) {
        this.sharedService.showSuccess(response.message);
        this.form.reset();
        this.setBudgetValue();
        this.generateRandomNumber();
        this.router.navigate(['thank-you']);
      }
    });
  }

  onBudgetSelect() {
    $('.footer_section select').css('color', '#fff');
  }

  setBudgetValue() {
    $('.footer_section select').css('color', '#4b4d6c');
    this.form.controls['project_budget'].setValue(this.budgets[0], { onlySelf: true });
  }

  checkWhiteSpace(event) {
    if (event.keyCode === 32 && this.form.controls.name.value.length === 1) {
      this.form.controls['name'].setValue('', { onlySelf: true });
    }
  }

  generateRandomNumber() {
    this.form.controls['userResult'].setValue('');
    this.x = Math.trunc(Math.random() * 5);
    this.y = Math.trunc(Math.random() * 5);
    this.result = this.x + this.y;
  }

  ngOnDestroy() {
    // $('.customer-logos').slick('unslick');
    // this.metaService.removeCanonicalLink();
  }
  onChange(e) {
    this.form.patchValue({
      project_nda: (e.target.checked) ? 1 : 0
    });
  }

}
