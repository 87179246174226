<!-- About US Banner -->
<section class="w-100 float-left about_us_page">
    <div class="container">
        <div class="w-100 float-left">
            <h1 class="banner_title text-center">Hiring Model</h1>
            <h2 class="banner_sub_title text-center">Meeting Diverse Requirements of Modern Business</h2>
        </div>
    </div>
</section>
<section class="w-100 float-left">
        <div class=" container">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb ">
                    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Hiring Model</li>
                </ol>
            </nav>
        </div>
    </section>

<!-- Engagement Models About -->
<section class="w-100 float-left about_we">
    <div class="container">
        <div class="main_title d-flex justify-content-center" data-aos="zoom-in">
            <h2 class="line"><span>Engagement </span> Models</h2>
        </div>

        <p class="text-center" data-aos="zoom-in">
            InheritX Solutions offers the best hiring models to address ever-changing business needs and the growing challenges of the corporate sector. Our enthusiastic and experienced professionals havea knack for developing high-quality software solutions for
            modern business. With state-of-the-art infrastructure and over 120 dedicated employees, InheritX Solutions can work on any size project worldwide. We have global footprints in Europe and the USA to serve our clientele in the most effective
            way. You can hire our developers to work on-site or remotely for your project at reasonable rates and complete flexibility.
        </p>
        <p class="text-center mb-0" data-aos="zoom-in">
            We keep focussing on providing high-end IT solutions in web and mobile app development domains. Our engagement models are free from any hidden charges or additional costs so that you can experience a transparent hiring process. You can have complete control
            over your project every time as we remain in touch with you at every step of the development process. Regular reporting and 24/7 assistance are just a few of many benefits when you hire developers from InheritX.
        </p>

    </div>
</section>

<!-- Model Factors -->
<section class="w-100 float-left modal_factor_section">
    <div class="container">
        <!-- Company Offers -->
        <div class="Company_offers_section float-left w-100">
            <div class="main_title d-flex justify-content-center" data-aos="zoom-in">
                <h2 class="line"><span>What we </span> Offer</h2>
            </div>
            <h3 class="sub_title text-center" data-aos="zoom-in">Let’s have a look at our hiring models.</h3>
        </div>

        <div class="w-100 float-left modal_box2">
            <div class="row align-items-center">
                <div class="col-xl-6 col-lg-8 col-md-12 col-sm-12 col-12 order-lg-1 order-2" data-aos="zoom-in" >
                    <div class="modal_box_right">
                        <div class="modal_title2 text-lg-left text-center">Dedicated Resource Engagement (DRE)</div>
                        <p class="text-lg-left text-justify">A Dedicated Resource model is a relationship where the Inheritx will provide resources to you full time and you have to pay for them on a per month basis. Developers will work just for you, so your work will be always highest priority
                            for them. You can consider that as your team only, just sitting in different office. You can hire our certified IT professionals to meet your IT and business needs effectively through Dedicated Resource Engagement model. You
                            can hire dedicated professionals from our 120+ in-house developers for web design & development, mobile app development, enterprise software development, and digital marketing.</p>

                    </div>
                    <button class="main_btn modal_btn_left float-left" routerLink="/contact-us">Get FREE QUOTE</button>
                </div>
                <div class="col-xl-6 col-lg-4 col-md-12 col-sm-12 col-12 d-flex order-lg-2 order-1" data-aos="zoom-in" >
                    <img src="assets/images/hiring/Dedicated Resource Engagement.png" class="w-100 img-fluid py-lg-0 py-3" alt="Dedicated Resource"/>
                </div>
            </div>
            <div class="row mt-5 align-items-center">
                <div class="col-xl-6 col-lg-4 col-md-12 col-sm-12 col-12 d-flex" data-aos="zoom-in" >
                    <img src="assets/images/hiring/Fixed-Price-Model.png" class="w-100 img-fluid" alt="Fixed-Price-Model"/>
                </div>

                <div class="col-xl-6 col-lg-8 col-md-12 col-sm-12 col-12" data-aos="zoom-in" >
                    <div class="modal_box_right ">
                        <div class="modal_title2 text-lg-right text-center">Fixed Price Model</div>
                        <p class="text-lg-left text-justify">A Fixed Price model occurs when you provide detailed project spec and Inheritx commit to complete it for a fixed price. This setup can work with smaller, highly specified projects (e.g. developing a mobile app or MVP) but is not
                            well suited to agile development processes since larger projects tend to be defined as they progress.
                        </p>
                    </div>
                    <button class="main_btn modal_btn_right float-right" routerLink="/contact-us">Get FREE QUOTE</button>
                </div>
            </div>

            <div class="row mt-5 align-items-center">
                <div class="col-xl-6 col-lg-8 col-md-12 col-sm-12 col-12 order-lg-1 order-2" data-aos="zoom-in" >
                    <div class="modal_box_right">
                        <div class="modal_title2 text-lg-left text-center">Time & Material/Hourly Price Model</div>
                        <p class="text-lg-left text-justify">This model is suitable for clients who are not having clear roadmap about the amount of work. The pricing is determined based on the man hours required to complete the project i.e. hourly charging. When the specification of project,
                            scope of work and implementation of project plan cannot be effectively estimated during the initial phase, T & M model is the best option to choose and proceed with the development.
                        </p>
                    </div>
                    <button class="main_btn modal_btn_left float-left" routerLink="/contact-us">Get FREE QUOTE</button>
                </div>
                <div class="col-xl-6 col-lg-4 col-md-12 col-sm-12 col-12 d-flex order-lg-2 order-1" data-aos="zoom-in" >
                    <img src="assets/images/hiring/Time&MaterialHourlyPriceModel.png" class="w-100 img-fluid" alt="Time & Material/Hourly Price Model"/>
                </div>

            </div>
            <!-- <div class="row mt-5">
    <div class="col-xl-6 col-lg-6" data-aos="flip-right" data-aos-easing="ease-in-sine">
      <img src="assets/images/hiring/modal_box.png" class="w-100 img-fluid" />
    </div>

    <div class="col-xl-6 col-lg-6" data-aos="flip-right" data-aos-easing="ease-in-sine">
      <div class="modal_box_right modal_box_space">
        <div class="modal_title2 text-right">Time & Material/Hourly Price Model</div>
        <p class="text-right">This is one of the most popular engagement models. It is based on the development
          process, and you need to pay per hour to the developers. This model is suitable for software development
          projects because these projects have a huge room for scope, specifications, implementation, and
          deliverables. Whenever flexibility is necessary and plans are not completely defined, this model works
          wonders. You can hire our dedicated professionals at reasonable hourly rates for your project while
          getting rid of overpriced quotes and hidden charges. You can pay as your project advances and initiate the
          project almost immediately because there is no need to decide upon the final outcome in the beginning.</p>

      </div>
      <button class="main_btn float-right modal_btn_right">Get FREE QUoTE</button>
    </div>
  </div> -->
        </div>
    </div>
</section>
<section class="w-100 float-left modal_expert">
    <div class="w-100 float-left modal_expert_text" data-aos="zoom-in">
        Still puzzled about engagement models? <br> We’re here to help you!
    </div>
    <div class="w-100 float-left text-center" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
        <button class="main_btn" routerLink="/contact-us">Consult our experts</button>
    </div>
</section>