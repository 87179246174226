import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { Meta, Title } from '@angular/platform-browser';
@Injectable({
  providedIn: 'root'
})
export class SharedService {

  baseUrl: string = environment.Base_URL;

  constructor(
    private title: Title, private meta: Meta,
    @Inject(HttpClient) private http: HttpClient,
    @Inject(NgxSpinnerService) private spinner: NgxSpinnerService,
    @Inject(ToastrService) private toastr: ToastrService,
  ) { }

  showSpinner() {
    this.spinner.show();
  }

  hideSpinner() {
    this.spinner.hide();
  }

  showSuccess(message) {
    this.toastr.success(message);
  }

  showError(message) {
    this.toastr.error(message);
  }

  removeError() {
    this.toastr.clear();
  }

  postFormData(data) {
    return this.http.post(this.baseUrl + 'contactform', data);
  }

  getBlogsData(page: number, category: string, searchString?: string) {
    let url = this.baseUrl + 'v1/blog';
    if (page) {

      url = url + '/' + page;
    }
    if (category) {
      url = url + '/?category_slug=' + category;
    }
    if (searchString) {
      url = url + '/?search=' + searchString;
    }
    return this.http.get(url);
  }


  getBlogPages() {
    return this.http.get(this.baseUrl + 'v1/inxblog');
  }
  getSingleCategoryBlogs(page, data) {
    return this.http.get(this.baseUrl + 'v1/inxblog/' + page + '/?category_slug=' + data);
  }

  getSearchBlogs(page, data) {
    return this.http.get(this.baseUrl + 'v1/blogsearch/' + page + '/?search=' + data);
  }
  getBlogDetails(id: string) {
    return this.http.get(this.baseUrl + 'v1/inxblogdetails/' + id);
  }

  getContentData() {
    return this.http.get(this.baseUrl + 'home');
  }

  getLocationsData() {
    return this.http.get(this.baseUrl + 'locations');
  }

  getCurrentOpenings() {
    return this.http.get(this.baseUrl + 'v1/career');
  }

  getGalleryImages() {
    return this.http.get(this.baseUrl + 'v1/gallery');
  }

  getPortFolioData() {
    return this.http.get(this.baseUrl + 'portfolio');
  }

  showBlogDetails(id: string) {
    return this.http.get(this.baseUrl + 'v1/blogdetails/' + id);
  }

  subscribeBlogs(data) {
    return this.http.post(this.baseUrl + 'v1/subscription', data);
  }

  getFaqs(category?: string) {
    if (!category) {
      return this.http.get(this.baseUrl + 'v1/faq');
    } else {
      return this.http.get(this.baseUrl + 'v1/faq?category_slug=' + category);
    }
  }

  getPhoneAndEmail() {
    return this.http.get(this.baseUrl + 'v1/contactinfo');
  }

  getCareerDetails(id) {
    return this.http.get(this.baseUrl + 'v1/careerdetails/' + id);
  }

  postCareerFormData(data) {
    return this.http.post(this.baseUrl + 'v1/careerform', data);
  }

}
