
<!-- Contact Us Banner -->
<section class="w-100 float-left about_us_page">
  <div class="container">
    <div class="w-100 float-left">
      <h1 class="banner_title text-center">Contact Us</h1>
      <h2 class="banner_sub_title text-center">We’re just a call or email away! We are happy to help you, always.</h2>
    </div>
  </div>
</section>

<section class="w-100 float-left">
    <div class=" container">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb ">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Contact Us</li>
            </ol>
        </nav>
    </div>
</section>

<!-- Contact Us Page-->
<section class="w-100 float-left contact_us-main">
  <div class="container">
    <div class="main_title d-flex justify-content-center" data-aos="zoom-in">
      <h2 class="line"><span>Got a Project</span> in Mind? </h2>
    </div>
    <h3 class="sub_title text-center w-100 float-left aos-init aos-animate" data-aos="zoom-in">
      Help us shape your idea into reality.
    </h3>

    <div class="w-100 float-left contact_us footer_section">
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="row" data-aos="zoom-in">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="footer_textbox">
                  <input type="text" name="name" placeholder="Name*" formControlName="name"
                    (keyup)="checkWhiteSpace($event)" pattern="^[^ ].+[^ ]$"/>
                  <span class="errorMessage"
                    *ngIf="form.controls.name.errors && (form.controls.name.touched && form.controls.name.invalid)">
                    Name is required
                  </span>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="footer_textbox">
                  <input type="email" name="email" placeholder="Email*" formControlName="email" pattern="^[^ ].+[^ ]$"/>
                  <span class="errorMessage"
                    *ngIf="form.controls.email.errors && (form.controls.email.touched && form.controls.email.invalid)">
                    <span *ngIf="form.controls.email.errors.required">Email is required.</span>
                    <span *ngIf="form.controls.email.errors.pattern">Please enter a valid email.</span>
                  </span>
                </div>
              </div>
            </div>
            <div class="row" data-aos="zoom-in">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="footer_textbox">
                  <input type="text" name="country" placeholder="Country*" formControlName="country" pattern="^[^ ].+[^ ]$" />
                  <span class="errorMessage"
                    *ngIf="form.controls.country.errors && (form.controls.country.touched && form.controls.country.invalid)">
                    country is required
                  </span>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="footer_textbox">
                  <input type="text" name="phone" placeholder="Phone*" formControlName="phone" />
                  <span class="errorMessage"
                    *ngIf="form.controls.phone.errors && (form.controls.phone.touched && form.controls.phone.invalid)">
                    <span *ngIf="form.controls.phone.errors.required">Phone is required</span>
                    <span *ngIf="form.controls.phone.errors.pattern">Phone should be numeric</span>
                    <span
                      *ngIf="!form.controls.phone.errors.pattern && (form.controls.phone.errors.minlength || form.controls.phone.errors.maxlength)">
                      <span *ngIf="form.controls.phone.errors.minlength">
                        Phone must be minimum 10 digits
                      </span>
                      <span *ngIf="form.controls.phone.errors.maxlength">
                        Phone must be maximum 15 digits
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div class="row" data-aos="zoom-in">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="footer_textbox">
                  <input type="text" name="project_type" placeholder="Project Type" formControlName="project_type" />
                  <span class="errorMessage"
                    *ngIf="form.controls.project_type.errors && (form.controls.project_type.touched && form.controls.project_type.invalid)">
                    Project Type is required
                  </span>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="footer_textbox">
                  <select onchange="this.className=this.options[this.selectedIndex].className" class=""
                    formControlName="project_budget">
                    <option *ngFor="let b of budgets; let i = index" class="white_option" [disabled]="i == 0">{{b}}
                    </option>
                  </select>
                  <span class="errorMessage"
                    *ngIf="form.controls.project_budget.touched && (form.controls.project_budget.invalid || (form.controls.project_budget.value == 'Select Your Budget*'))">
                    Please select a Budget
                  </span>
                </div>
              </div>
            </div>
            <div class="row" data-aos="zoom-in">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 col-sm-12 col-12">
                <div class="footer_textbox">
                  <textarea rows="4" placeholder="Brief your details...*" formControlName="project_details" pattern="^[^ ].+[^ ]$"></textarea>
                  <span class="errorMessage"
                    *ngIf="form.controls.project_details.errors && (form.controls.project_details.touched && form.controls.project_details.invalid)">
                    Details are required
                  </span>
                </div>
              </div>
            </div>
            <div class="row pb-4" data-aos="zoom-in">

              <div class="col-xl-1 col-lg-2 col-md-2 col-sm-3 col-3"><input type="text" value={{x}} class="text-center"
                  disabled /></div>
              <label class="check_label">+</label>
              <div class="col-xl-1 col-lg-2 col-md-2 col-sm-3 col-3"><input type="text" value={{y}} class="text-center"
                  disabled /></div>
              <label class="check_label">=</label>
              <div class="col-xl-2 col-lg-4 col-md-4 col-sm-5 col-5"><input type="text"
                  class="text-center" formControlName="userResult"></div>

            </div>
            <!-- <div class="row" data-aos="zoom-in">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="footer_textbox">
                  {{x}} + {{y}} = ?
                  <input type="text" formControlName="userResult">
                </div>
              </div>
            </div> -->
            <div class="row" data-aos="zoom-in">
              <div class="col-lg-12">
                <label class="checkbox">Request Non-Disclosure Agreement for a Confidential
                  Consultation.
                  <input type="checkbox" formControlName="project_nda" (change)="onChange($event)">
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
            <div class="w-100 text-center justify-content-md-start justify-content-center aos-init aos-animate"
              data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
              <button type="submit" class="main_btn text-uppercase">SCHEDULE A FREE CONSULTANCY</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<!--  our clients section  -->
<section class="w-100 float-left client_section">
  <div class="container">
    <div class="main_title d-flex justify-content-center" data-aos="zoom-in">
      <h2 class="line"><span>Our Valuable</span> Clients </h2>
    </div>
    <h3 class="sub_title text-center w-100 float-left" data-aos="zoom-in">We are pleased to serve one of the most
      reputed and respected brands across different sectors globally.</h3>
    <div class="w-100 float-left mt-4" data-aos="zoom-in">
      <section *ngIf="clients" class="customer-logos slider">
        <div class="slide client_logo" *ngFor="let image of clients">
          <img [src]="image.feature_image" alt="Clients">
        </div>
        <div class="slide client_logo" *ngFor="let image of clients">
          <img [src]="image.feature_image" alt="Clients">
        </div>
      </section>
    </div>
  </div>
</section>

<!-- Project & Career -->
<section class="w-100 float-left project-career">
  <div class="container">
    <div class="row">
      <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 pb-5">
        <div class="main_title d-flex justify-content-center" data-aos="zoom-in">
          <h2 class="line"><span>Project</span> Enquiries</h2>
        </div>
        <div class="w-100 float-left">
          <div class="row aos-init aos-animate" data-aos="zoom-in">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div class="project-details">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 578.106 578.106"
                  style="enable-background:new 0 0 578.106 578.106;" xml:space="preserve">
                  <g>
                    <g>
                      <path style="fill:#00C5DE;" d="M577.83,456.128c1.225,9.385-1.635,17.545-8.568,24.48l-81.396,80.781
                              c-3.672,4.08-8.465,7.551-14.381,10.404c-5.916,2.857-11.729,4.693-17.439,5.508c-0.408,0-1.635,0.105-3.676,0.309
                              c-2.037,0.203-4.689,0.307-7.953,0.307c-7.754,0-20.301-1.326-37.641-3.979s-38.555-9.182-63.645-19.584
                              c-25.096-10.404-53.553-26.012-85.376-46.818c-31.823-20.805-65.688-49.367-101.592-85.68
                              c-28.56-28.152-52.224-55.08-70.992-80.783c-18.768-25.705-33.864-49.471-45.288-71.299
                              c-11.425-21.828-19.993-41.616-25.705-59.364S4.59,177.362,2.55,164.51s-2.856-22.95-2.448-30.294
                              c0.408-7.344,0.612-11.424,0.612-12.24c0.816-5.712,2.652-11.526,5.508-17.442s6.324-10.71,10.404-14.382L98.022,8.756
                              c5.712-5.712,12.24-8.568,19.584-8.568c5.304,0,9.996,1.53,14.076,4.59s7.548,6.834,10.404,11.322l65.484,124.236
                              c3.672,6.528,4.692,13.668,3.06,21.42c-1.632,7.752-5.1,14.28-10.404,19.584l-29.988,29.988c-0.816,0.816-1.53,2.142-2.142,3.978
                              s-0.918,3.366-0.918,4.59c1.632,8.568,5.304,18.36,11.016,29.376c4.896,9.792,12.444,21.726,22.644,35.802
                              s24.684,30.293,43.452,48.653c18.36,18.77,34.68,33.354,48.96,43.76c14.277,10.4,26.215,18.053,35.803,22.949
                              c9.588,4.896,16.932,7.854,22.031,8.871l7.648,1.531c0.816,0,2.145-0.307,3.979-0.918c1.836-0.613,3.162-1.326,3.979-2.143
                              l34.883-35.496c7.348-6.527,15.912-9.791,25.705-9.791c6.938,0,12.443,1.223,16.523,3.672h0.611l118.115,69.768
                              C571.098,441.238,576.197,447.968,577.83,456.128z" />
                    </g>
                  </g>

                </svg>

                <a> {{contactInfo?.phone}}</a>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div class="project-details">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 14 14"
                  style="enable-background:new 0 0 14 14;" xml:space="preserve">
                  <g>
                    <g>
                      <path style="fill:#00C5DE;" d="M7,9L5.268,7.484l-4.952,4.245C0.496,11.896,0.739,12,1.007,12h11.986
                              c0.267,0,0.509-0.104,0.688-0.271L8.732,7.484L7,9z" />
                      <path style="fill:#00C5DE;" d="M13.684,2.271C13.504,2.103,13.262,2,12.993,2H1.007C0.74,2,0.498,2.104,0.318,2.273L7,8
                              L13.684,2.271z" />
                      <polygon style="fill:#00C5DE;" points="0,2.878 0,11.186 4.833,7.079     " />
                      <polygon style="fill:#00C5DE;" points="9.167,7.079 14,11.186 14,2.875     " />
                    </g>
                  </g>

                </svg>
                <a href="mailto:contact@inheritx.com"> {{contactInfo?.contact_email}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 career-mob">
        <div class="main_title d-flex justify-content-center" data-aos="zoom-in">
          <div class="line">Career</div>
        </div>
        <div class="w-100 float-left">
          <div class="row aos-init aos-animate" data-aos="zoom-in">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="project-details">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 14 14"
                  style="enable-background:new 0 0 14 14;" xml:space="preserve">
                  <g>
                    <g>
                      <path style="fill:#00C5DE;" d="M7,9L5.268,7.484l-4.952,4.245C0.496,11.896,0.739,12,1.007,12h11.986
                              c0.267,0,0.509-0.104,0.688-0.271L8.732,7.484L7,9z" />
                      <path style="fill:#00C5DE;" d="M13.684,2.271C13.504,2.103,13.262,2,12.993,2H1.007C0.74,2,0.498,2.104,0.318,2.273L7,8
                              L13.684,2.271z" />
                      <polygon style="fill:#00C5DE;" points="0,2.878 0,11.186 4.833,7.079     " />
                      <polygon style="fill:#00C5DE;" points="9.167,7.079 14,11.186 14,2.875     " />
                    </g>
                  </g>
                </svg>
                <a href="mailto:hr@inheritx.com"> {{contactInfo?.hr_email}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Find Us -->
<section class="w-100 float-left find-us">
  <div class="container">
    <div class="main_title d-flex justify-content-center aos-init aos-animate" data-aos="zoom-in">
      <h2 class="line"><span>Find</span> Us </h2>
    </div>
    <h3 class="sub_title text-center w-100 float-left aos-init aos-animate" data-aos="zoom-in">
      Headquartered in India, we have a global presence to serve our esteemed clients more effectively.
    </h3>
    <div class="w-100 float-left">
      <div class="row aos-init aos-animate" data-aos="zoom-in">
        <div *ngFor="let location of locations" class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 find-location">
          <div class="circle-icon">
            <img [src]="location.location_image" alt="location"/>
          </div>
          <div class="location-details">
            <h2>{{location.title}}</h2>
            <p>{{location.address1}}, {{location.address2}}, {{location.country}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Footer Section -->

<section class="w-100 float-left only-copy-right">
  <div class="container">
    <div class="row">
      <!-- <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 footer_copyright text-left">Copyright © {{year}} InheritX. All
        Right Reserved</div> -->
      <!-- <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 footer_copyright">
        <div class="footer_social_icon_section w-100 float-left d-flex justify-content-sm-end justify-content-center">
          <a *ngFor="let link of socialLinks" [href]="link.url" target="_blank">
            <div class="social_icon_box">
              <img [src]="link.src" alt="social icon"/>
            </div>
          </a>
        </div>
      </div> -->
    </div>
  </div>
</section>