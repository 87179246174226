import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactUsResolver } from './main/contact-us/contact-us.resolver';
import { CorporateProfileComponent } from './main/corporate-profile/corporate-profile.component';
import { ImagesComponent } from './images/images.component';
import { ContactUsComponent } from './main/contact-us/contact-us.component';
import { ThankYouComponent } from './main/thank-you/thank-you.component';
import { ErrorPageComponent } from './main/error-page/error-page.component';
import { WhyInheritxComponent } from './main/company/why-inheritx/why-inheritx.component';
import { AboutUsComponent } from './main/company/about-us/about-us.component';
import { HiringModalComponent } from './main/company/hiring-modal/hiring-modal.component';
import { CareerComponent } from './main/company/career/career.component';
import { CareerResolver } from './main/company/career/career.resolver';
import { BlogResolver } from './main/blog/blog.resolver';


const routes: Routes = [


  { path: '', loadChildren: () => import('./main/home/home.module').then(m => m.HomeModule) },
  { path: 'contact-us', component: ContactUsComponent, resolve: { contactus: ContactUsResolver } },
  { path: 'thank-you', component: ThankYouComponent },
  { path: 'corporate-profile/:file', loadChildren: () => import('./main/corporate-profile/corportate-profile.module').then(m => m.CorporateProfileModule) },
  { path: 'corporate_profile', redirectTo: 'portfolios' },
  { path: 'blog', loadChildren: () => import('./main/blog/blog.module').then(m => m.BlogModule) },
  { path: 'blog/:id', loadChildren: () => import('./main/blog-details/blog-details.module').then(m => m.BlogDetailsModule) },
  { path: 'category/:name', loadChildren: () => import('./main/category/category.module').then(m => m.CategoryModule) },
  { path: 'search/:keyword', loadChildren: () => import('./main/category/category.module').then(m => m.CategoryModule) },
  { path: 'portfolios', loadChildren: () => import('./main/our-portfolio/portfolio.module').then(m => m.PortfolioModule) },
  { path: 'why-inheritx', component: WhyInheritxComponent },
  { path: 'about', component: AboutUsComponent },
  { path: 'hiring-model', component: HiringModalComponent },
  { path: 'career-builder', component: CareerComponent, resolve: { career: CareerResolver } },
  { path: 'services', resolve: { blog: BlogResolver }, loadChildren: () => import('./main/services/services.module').then(m => m.ServicesModule) },
  { path: 'hire-resources', loadChildren: () => import('./main/hire-resources/hire-resources.module').then(m => m.HireResourcesModule) },


  { path: 'images/:file', component: ImagesComponent },
  { path: '**', component: ErrorPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabled', scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
