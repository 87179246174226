export class CommonFunction {
    public generateFormdata(data) {
        let formData: FormData = new FormData();
        formData.append('name', data.name);
        formData.append('email', data.email);
        formData.append('country', data.country);
        formData.append('phone', data.phone);
        formData.append('project_budget', data.project_budget);
        formData.append('project_type', data.project_type);
        formData.append('project_details', data.project_details);
        formData.append('project_nda', data.project_nda)
        return formData;
    }

    public loadScript(url: string) {
        const body = <HTMLDivElement>document.body;
        const script = document.createElement('script');
        script.innerHTML = '';
        script.src = url;
        script.type = 'text/javascript';
        script.async = false;
        script.defer = true;
        body.appendChild(script);
      }
}