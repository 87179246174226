import { Component, OnInit, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';

declare var $: any;

@Component({

    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
    data: any;
    showDropDown = false;
    constructor(
        private router: Router,
        private titleService: Title,
        private _eref: ElementRef,
        @Inject(PLATFORM_ID) private platformId: Object,
    ) { }
    ngOnInit() {
        this.setTitles(this.router.url);
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.setTitles(event.url);
                if (isPlatformBrowser(this.platformId)) {
                    $("#navbarNavDropdown").removeClass("show");
                }
            }
        });
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }

            // if (this.router.url == "/") {
            //   this.data.page = '/';
            // }else{
            //   this.data.page = this.router.url;
            // }
        });

        if (isPlatformBrowser(this.platformId)) {
            $("#myOutlet").on("click touchstart", function () {
                $("#navbarNavDropdown").removeClass("show");
            });
        }
    }


    setTitles(url) {
        if (url === '/') {
            this.titleService.setTitle('Custom Web & App Development Company USA, UK | InheritX Solution');
        }
        else if (url === '/why-inheritx') {
            this.titleService.setTitle('Best Web and Mobile App Solutions USA | InheritX Solution');
        }
        else if (url === '/about') {
            this.titleService.setTitle('Offshore Web & Mobile App Development Company | InheritX Solution');
        }
        else if (url === '/career-builder') {
            this.titleService.setTitle('Information Technology Jobs in India | InheritX Solution');
        }
        else if (url === '/services/iphone-app-development') {
            this.titleService.setTitle('Iphone App Development Services USA, UK, ZA, DE | InheritX Solution');
        }
        else if (url === '/services/android-app-development') {
            this.titleService.setTitle('Android App Development Services USA, UK, ZA, DE | InheritX Solution');
        }
        else if (url === '/services/kotlin-app-development') {
            this.titleService.setTitle('Kotlin App Development Company USA, UK, ZA, DE | InheritX Solution');
        }
        else if (url === '/services/flutter-app-development') {
            this.titleService.setTitle('Flutter App Development Services USA, UK, ZA, DE | InheritX Solution');
        }
        else if (url === '/services/react-native-app-development') {
            this.titleService.setTitle('React Native App Development Services USA, UK | InheritX Solution');
        }
        else if (url === '/services/ipad-app-development') {
            this.titleService.setTitle('Custom iPad App Development Services USA, UK, ZA | InheritX Solution');
        }
        else if (url === '/services/xamarin-app-development') {
            this.titleService.setTitle('Xamarin App Development Services USA, UK, ZA, DE | InheritX Solution');
        }
        else if (url === '/services/ionic-app-development') {
            this.titleService.setTitle('Ionic App Development Services USA, UK, ZA, DE | InheritX Solution');
        }
        else if (url === '/services/angularjs-development') {
            this.titleService.setTitle('AngularJS Web Development Services USA, UK | InheritX Solution');
        }
        else if (url === '/services/reactjs-development') {
            this.titleService.setTitle('ReactJS Web & App Development Services USA, UK | InheritX Solution');
        }
        else if (url === '/services/node.js-development') {
            this.titleService.setTitle('Node JS Web Application Development Services USA | InheritX Solution');
        }
        else if (url === '/services/php-development') {
            this.titleService.setTitle('PHP Web Development Services USA, UK, ZA, DE | InheritX Solution');
        }
        else if (url === '/services/java-development') {
            this.titleService.setTitle('Java Web & App Development Services USA, UK, ZA | InheritX Solution');
        }
        else if (url === '/services/wordpress-development') {
            this.titleService.setTitle('Wordpress Web Development Services USA, UK, ZA | InheritX Solution');
        }
        else if (url === '/services/magento-development') {
            this.titleService.setTitle('Magento Web Development Services USA, UK, ZA, DE | InheritX Solution');
        }
        else if (url === '/services/aws-services') {
            this.titleService.setTitle('AWS Cloud Development Services USA, UK, ZA, DE | InheritX Solution');
        }
        else if (url === '/services/google-cloud-services') {
            this.titleService.setTitle('Google Cloud Computing Services USA, UK, ZA, DE | InheritX Solution ');
        }
        else if (url === '/services/pwa-development') {
            this.titleService.setTitle('Progressive Web App Development Services USA, UK | InheritX Solution');
        }
        else if (url === '/services/seo-services') {
            this.titleService.setTitle('Professional SEO Services Provider USA, UK, ZA | InheritX Solution');
        }
        else if (url === '/services/iot-development') {
            this.titleService.setTitle('IOT App Development Services USA, UK, ZA | InheritX Solution');
        }
        else if (url === '/hire-resources/hire-iphone-developer') {
            this.titleService.setTitle('Hire Iphone App Developer USA, UK, ZA, DE | InheritX Solution');
        }
        else if (url === '/hire-resources/hire-android-developer') {
            this.titleService.setTitle('Hire Android App Developer USA, UK, ZA, DE | InheritX Solution');
        }
        else if (url === '/hire-resources/hire-react-native-developer') {
            this.titleService.setTitle('Hire React Native Developer USA, UK, ZA, DE | InheritX Solution');
        }
        else if (url === '/hire-resources/hire-flutter-developer') {
            this.titleService.setTitle('Hire Flutter App Developers USA, UK, ZA, DE | InheritX Solution');
        }
        else if (url === '/hire-resources/hire-phonegap-developer') {
            this.titleService.setTitle('Hire Phonegap Developer USA, UK, ZA, DE | InheritX Solution');
        }
        else if (url === '/hire-resources/hire-angularjs-developer') {
            this.titleService.setTitle('Hire AngularJS Developer USA, UK, ZA, DE | InheritX Solution');
        }
        else if (url === '/hire-resources/hire-react-developer') {
            this.titleService.setTitle('Hire AngularJS Developer USA, UK, ZA, DE | InheritX Solution');
        }
        else if (url === '/hire-resources/hire-node-js-developer') {
            this.titleService.setTitle('Hire NodeJS Developer USA, UK, ZA, DE | InheritX Solution');
        }
        else if (url === '/hire-resources/hire-php-developer') {
            this.titleService.setTitle('Hire PHP Developer USA, UK, ZA, DE | InheritX Solution');
        }
        else if (url === '/hire-resources/hire-laravel-developer') {
            this.titleService.setTitle('Hire Laravel Developer USA, UK, ZA, DE | InheritX Solution');
        }
        else if (url === '/hire-resources/hire-python-developer') {
            this.titleService.setTitle('Hire Python Developers USA, UK, ZA, DE | InheritX Solution');
        }
        else if (url === '/hire-resources/hire-wordPress-developer') {
            this.titleService.setTitle('Hire Wordpress Expert Developer USA, UK, ZA, DE | InheritX Solution');
        }
        else if (url === '/hire-resources/hire-asp-developer') {
            this.titleService.setTitle('Hire ASP Developers USA, UK, ZA, DE | InheritX Solution');
        }
        else if (url === '/blog') {
            this.titleService.setTitle('Technology Tips | Tech Blogs USA | InhertiX Solution');
        }
        else if (url === '/main/blog-details') {
            this.titleService.setTitle('Web and Mobile App development Blog | Inheritx');
        }
        else if (url === '/blog/{{blog.slug}}') {
            this.titleService.setTitle('Web and Mobile App development Blog | Inheritx');
        }
        else if (url === '/portfolios') {
            this.titleService.setTitle('Our Portfolio for Apps & Web Development Services | InheritX Solution');
        }
        else if (url === '/contact-us') {
            this.titleService.setTitle('Contact us for App and Web Development Services | InheritX Solution');
        }
        else if (url === '/services') {
            this.titleService.setTitle('Web & Mobile App Development Services USA, UK | InheritX Solution');
        }
        else if (url === '/hire-resources') {
            this.titleService.setTitle('Hire Dedicated Web & App Developer USA, UK, ZA | InheritX Solution');
        }
        else if (url === '/hiring-model') {
            this.titleService.setTitle('Consumer Engagement Model | InheritX Solution');
        }
        else {
            this.titleService.setTitle('InheritX Solutions');
        }
    }
}

