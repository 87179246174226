import { BrowserModule, BrowserTransferStateModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { ContactUsComponent } from './main/contact-us/contact-us.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthInterceptor } from './auth.interceptor';
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from './shared/shared.module';

// import { PdfViewerModule } from 'ng2-pdf-viewer';
// import {TransferHttpCacheModule} from '@nguniversal/common';
import { ImagesComponent } from './images/images.component';


import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ErrorPageComponent } from './main/error-page/error-page.component';
import { ThankYouComponent } from './main/thank-you/thank-you.component';
import { CorporateProfileComponent } from './main/corporate-profile/corporate-profile.component';
import { AboutUsComponent } from './main/company/about-us/about-us.component';
import { HiringModalComponent } from './main/company/hiring-modal/hiring-modal.component';
import { CareerComponent } from './main/company/career/career.component';
import { WhyInheritxComponent } from './main/company/why-inheritx/why-inheritx.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,

    ImagesComponent,
    ErrorPageComponent,
    ThankYouComponent,
    // CorporateProfileComponent,
    ContactUsComponent,
    AboutUsComponent,
    HiringModalComponent,
    CareerComponent,
    WhyInheritxComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserTransferStateModule,
    //  TransferHttpCacheModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    SharedModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
     
  ],

  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }, Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string) {
    const platform = isPlatformBrowser(platformId) ?
      'in the browser' : 'on the server';
    console.log(`Running ${platform} with appId=${appId}`);
  }
}
